import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import { PerDiemDayCalculationDataProps } from 'views/Reimbursement/hooks/usePerDiemItemCalculation';

export const ReimbursableRateCell = ({
  getValue,
}: CellContext<PerDiemDayCalculationDataProps, string | undefined>) => {
  const reimbursableRate = getValue();

  if (!reimbursableRate) {
    return <div className="text-right text-[0.875rem]">-</div>;
  }

  return (
    <TruncatedText className="text-right text-[0.875rem]">
      {reimbursableRate}
    </TruncatedText>
  );
};
