import clsx from 'clsx';
import { Icon } from '@candisio/design-system/next';

type AddNewRowProps = {
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const AddNewRow = ({ disabled, className, onClick }: AddNewRowProps) => {
  return (
    <div className="w-full">
      <button
        type="button"
        data-cy="add-new-booking-button"
        className={clsx(className)}
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();
          if (disabled) return;
          onClick?.();
        }}
      >
        <Icon icon="plus" className="w-4 h-4" />
        <span>Add new</span>
      </button>
    </div>
  );
};

// TODO translation
