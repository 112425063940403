import {
  ReimbursementItemFormPerDiemDay,
  ReimbursementItemFormPerDiemMeal,
} from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';

export type SelectionVariant = 'none' | 'partial' | 'all';

interface GetMealsSelectorVariantParams {
  days: ReimbursementItemFormPerDiemDay[];
  id: string;
  startIndex?: number;
  endIndex?: number;
}

export const getMealsSelectorVariant = ({
  days,
  id,
  endIndex,
  startIndex,
}: GetMealsSelectorVariantParams): SelectionVariant => {
  const slicedDays = days.slice(startIndex, endIndex);
  const daysWithSelectedMeal = slicedDays.filter(day =>
    day.meals.includes(id as ReimbursementItemFormPerDiemMeal)
  ).length;

  if (daysWithSelectedMeal === slicedDays.length) {
    return 'all';
  }
  if (daysWithSelectedMeal !== slicedDays.length && daysWithSelectedMeal > 0) {
    return 'partial';
  }
  return 'none';
};
