import {
  ReimbursementItemFormPerDiemMeal,
  ReimbursementItemsFormOutput,
} from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { generatePerDiemFormFieldNames } from '../utils/generatePerDiemFieldNames';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import styles from './DaysStep.module.css';
import { Day } from './Day';
import { uniq, without } from 'lodash';
import { perDiemMealHeaderButtons } from '../PerDiemForm';
import { PerDiemMealsMultiSelectorButtons } from './PerDiemMealsMultiSelectorButtons/PerDiemMealsMultiSelectorButtons';
import { useCallback, useState } from 'react';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';
import { PerDiemSegmentSummary } from './PerDiemSegmentSummary';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';
import { ReducedDays } from './ReducedDays/ReducedDays';
import { getMealsSelectorVariant } from '../utils/getMealsSelectorVariant';
import { amountFormatNonNull } from 'utils/format';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { PerDiemCalculationContainer } from './PerDiemTable/PerDiemCalculationContainer';
import { Link, useTheme } from '@candisio/design-system';

interface DaysStepProps {
  formIndex: number;
  formMethods: UseFormReturn<ReimbursementItemsFormOutput>;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
  perDiemItemDetails?: PerDiemItemDetails;
  isLoadingPerDiemItemDetails: boolean;
  totalAmount: number | null | undefined;
  isPerDiemFormEditable: boolean;
  isEditSegmentPending: boolean;
  onEditPerDiemSegment: (index: number) => Promise<void>;
}

const MAX_DAYS_TO_SHOW = 5;

export const DaysStep = ({
  formIndex,
  formMethods,
  onUpdatePerDiemDays,
  perDiemItemDetails,
  isLoadingPerDiemItemDetails,
  totalAmount,
  isPerDiemFormEditable,
  isEditSegmentPending,
  onEditPerDiemSegment,
}: DaysStepProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generatePerDiemFormFieldNames(formIndex);
  const { fontSizes } = useTheme();

  const [isCalculationModalOpen, setIsCalculationModalOpen] = useState(false);
  const { colors } = useTheme();
  const { watch, setValue } = formMethods;

  const days = watch(`reimbursementItems.${formIndex}.days`);

  const { fields: daysFields = [] } = useFieldArray<
    ReimbursementItemsFormOutput,
    `reimbursementItems.${number}.days`
  >({
    control: formMethods.control,
    name: fields.days,
  });

  const formattedAmount = totalAmount
    ? amountFormatNonNull(totalAmount, 'EUR')
    : amountFormatNonNull(0, 'EUR');

  const shouldTrimDays = daysFields.length > MAX_DAYS_TO_SHOW;

  const selectAllColumn = useCallback(
    ({
      id,
      value,
      startIndex = 0,
      endIndex = undefined,
    }: SelectAllColumnPerDiemParams) => {
      const slicedDays = days.slice(startIndex, endIndex);

      slicedDays.forEach((day, index) => {
        const updateDayIndex = index + startIndex;
        let updatedMeals: ReimbursementItemFormPerDiemMeal[] = [];
        if (value) {
          updatedMeals = uniq([
            ...day.meals,
            id as ReimbursementItemFormPerDiemMeal,
          ]);
        } else {
          updatedMeals = without(
            [...day.meals],
            id as ReimbursementItemFormPerDiemMeal
          );
        }

        setValue(
          `reimbursementItems.${formIndex}.days.${updateDayIndex}.meals`,
          updatedMeals
        );

        onUpdatePerDiemDays(formIndex)();
      });
    },
    [onUpdatePerDiemDays, setValue, days, formIndex]
  );

  const getMultiSelectorState = useCallback(
    ({
      id,
      startIndex = 0,
      endIndex = undefined,
    }: GetMultiSelectorStatePerDiemParams) => {
      return getMealsSelectorVariant({ id, days, endIndex, startIndex });
    },
    [days]
  );

  const editPerDiemSegment = useCallback(async () => {
    onEditPerDiemSegment(formIndex);
  }, [onEditPerDiemSegment, formIndex]);

  return (
    <div className="px-6 grid gap-4">
      <PerDiemSegmentSummary
        perDiemItemDetails={perDiemItemDetails}
        isLoading={isLoadingPerDiemItemDetails}
        isPerDiemFormEditable={isPerDiemFormEditable}
        onEditPerDiemSegment={editPerDiemSegment}
        isEditSegmentPending={isEditSegmentPending}
      />
      <div className="grid gap-3">
        <span className="font-medium" style={{ fontSize: fontSizes.large }}>
          {t('reimbursementView.middleSection.form.perDiem.days.mealsHeader')}
        </span>
        <ol className={styles['day-list']}>
          <li className={styles['day-item']}>
            <PerDiemMealsMultiSelectorButtons
              onSelectAll={selectAllColumn}
              onGetMultiSelectorState={getMultiSelectorState}
              buttons={perDiemMealHeaderButtons}
            />
          </li>
          {shouldTrimDays ? (
            <ReducedDays
              days={daysFields}
              formIndex={formIndex}
              onGetMultiSelectorState={getMultiSelectorState}
              onSelectAllColumn={selectAllColumn}
              onUpdatePerDiemDays={onUpdatePerDiemDays}
            />
          ) : (
            daysFields.map((day, index) => {
              return (
                <Day
                  key={day.id}
                  date={day.date}
                  formIndex={formIndex}
                  dayIndex={index}
                  onUpdatePerDiemDays={onUpdatePerDiemDays}
                />
              );
            })
          )}
        </ol>
      </div>
      <div className="grid gap-2">
        <span className="font-medium" style={{ fontSize: fontSizes.large }}>
          {t(
            'reimbursementView.middleSection.form.perDiem.days.expectedReimbursement'
          )}
        </span>
        <div className="grid">
          <span
            className="font-semibold leading-[2rem]"
            style={{ fontSize: fontSizes.xxlarge }}
          >
            {formattedAmount}
          </span>
          <Link
            icon="caretRight"
            iconPosition="right"
            justifySelf="start"
            style={{ color: colors.gray800 }}
            onClick={() => setIsCalculationModalOpen(true)}
          >
            {t(
              'reimbursementView.middleSection.form.perDiem.days.viewCalculationCta'
            )}
          </Link>
        </div>
        {isCalculationModalOpen && (
          <PerDiemCalculationContainer
            isVisible
            onClose={() => setIsCalculationModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};
