import { useMemo } from 'react';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useLastSaveIndicator } from '../utils/useLastSaveIndicator';
import { AutoSaveStatus } from './AutoSaveStatus';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface AutoSaveStatusContainerProps {
  isSavePending?: boolean;
  reimbursement?: Reimbursement;
  reimbursementItems: ReimbursementItemForList[];
}

export const AutoSaveStatusContainer = ({
  isSavePending,
  reimbursement,
  reimbursementItems,
}: AutoSaveStatusContainerProps) => {
  const { isReimbursementUpdatePending } = useReimbursementFormsContext();

  const isUpdatePending = isSavePending || isReimbursementUpdatePending;

  const mostRecentUpdatedAt = useMemo(() => {
    const timestamps: Date[] = [];

    if (reimbursement?.updatedAt) {
      timestamps.push(new Date(reimbursement.updatedAt));
    }

    for (const item of reimbursementItems) {
      if (item.updatedAt) {
        timestamps.push(new Date(item.updatedAt));
      }
    }

    const mostRecentDate = timestamps.reduce((mostRecentDate, currentDate) =>
      currentDate > mostRecentDate ? currentDate : mostRecentDate
    );

    return timestamps.length > 0 ? mostRecentDate : null;
  }, [reimbursementItems, reimbursement]);

  const { showRecentStyle, formattedTime } = useLastSaveIndicator({
    mostRecentUpdatedAt,
  });

  return (
    <AutoSaveStatus
      isSavePending={isUpdatePending}
      showRecentStyle={showRecentStyle}
      timestamp={formattedTime}
    />
  );
};
