import { Grid, Paragraph, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ComponentProps, ElementRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionConfirm } from 'views/Inbox/DocumentProcessing/components/ActionConfirm';

export const UnlinkActionConfirm = forwardRef<
  ElementRef<typeof ActionConfirm>,
  Omit<ComponentProps<typeof ActionConfirm>, 'informationText'> & {
    fallbackDescription?: string;
  }
>(
  (
    {
      fallbackDescription = '',
      popoverProps,
      isActionPending,
      closePopover,
      handleConfirm,
    },
    ref
  ) => {
    const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
    const { isOnlyApprover } = useUserRoles();

    const descriptionKey = isOnlyApprover
      ? 'transactionDetailsCardContextMenu.approver.description'
      : fallbackDescription;

    const translations = {
      title: t('transactionDetailsCardContextMenu.title'),
      description: t(descriptionKey),
      cancelLabel: t('transactionDetailsCardContextMenu.cancelCTALabel'),
      confirmLabel: t('transactionDetailsCardContextMenu.confirmCTALabel'),
    } as const;

    const informationText = (
      <Grid gap="space8">
        <Text fontWeight="bold">{translations.title}</Text>
        <Paragraph>{translations.description}</Paragraph>
      </Grid>
    );

    return (
      <ActionConfirm
        ref={ref}
        popoverProps={popoverProps}
        isActionPending={isActionPending}
        closePopover={closePopover}
        handleConfirm={handleConfirm}
        informationText={informationText}
        cancelLabel={translations.cancelLabel}
        confirmLabel={translations.confirmLabel}
        maxWidth="450px"
      />
    );
  }
);
