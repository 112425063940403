import { formatDateTime } from 'views/Reimbursement/utils/formatDateTime';

interface DateTimeDisplayProps {
  dateTimeString: string;
  timezone?: string;
}

export const DateTimeDisplay = ({
  dateTimeString,
  timezone = 'CET',
}: DateTimeDisplayProps) => {
  const formattedDateTime = formatDateTime(dateTimeString);

  return (
    <span className="text-sm font-medium whitespace-nowrap">
      {formattedDateTime} <span className="text-gray-500">{timezone}</span>
    </span>
  );
};
