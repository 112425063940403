import {
  ButtonProps,
  Icon,
  Modal,
  ModalProps,
  useModal,
  useTheme,
} from '@candisio/design-system';
import { WideButton } from 'components/WideButton';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { TransactionList } from './TransactionList';
import { css } from '@emotion/react';

type AddTransactionsActionProps = {
  documentId: string;
};

const ButtonComponent = (props: ButtonProps) => {
  const { colors } = useTheme();

  return (
    <WideButton
      variant="tertiary"
      icon={<Icon icon="creditCardOutline" size="space16" />}
      style={{ border: '1px solid gray250' }}
      css={css`
          &:hover {
            background: ${colors.gray100};
          }
          `}
      {...props}
    />
  );
};
export const AddTransactionsAction = ({
  documentId,
}: AddTransactionsActionProps) => {
  const { triggerProps, modalProps, isOpen, close } = useModal();
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  const translationKey = 'addTransactionModal.linkTransaction';

  return (
    <>
      <ButtonComponent {...triggerProps}>{t(translationKey)}</ButtonComponent>
      <AddTransactionsModal
        modalProps={modalProps}
        isOpen={isOpen}
        documentId={documentId}
        onClose={close}
      />
    </>
  );
};

type AddTransactionsModalProps = {
  documentId: string;
  modalProps?: ModalProps;
  isOpen: boolean;
  onClose: () => void;
};

export const AddTransactionsModal = ({
  documentId,
  modalProps = {},
  isOpen,
  onClose,
}: AddTransactionsModalProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);

  if (!isOpen) return null;

  return (
    <Modal
      {...modalProps}
      shouldCloseOnBlur
      background="gray200"
      closeLabel={t('addTransactionModal.close')}
      width="80vw"
      isOpen={isOpen}
      onClose={onClose}
      padding="0"
      scrollDirection="none"
      title={t('addTransactionModal.header')}
    >
      <TransactionList documentId={documentId} onSuccess={onClose} />
    </Modal>
  );
};
