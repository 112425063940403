import { useMemo } from 'react';
// biome-ignore lint/nursery/noRestrictedImports: <explanation>
import { useParams } from 'react-router-dom';
import {
  DocumentCurrency,
  PerDiemItem,
  usePerDiemItemCalculationQuery,
} from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useFormatPerDiemDay } from '../utils/useFormatPerDiemDay';

export type DayType = 'HALF_DAY' | 'FULL_DAY';

export type MealRate = {
  rateDifference: string;
  percentageDifference: string;
};

export interface PerDiemDayCalculationDataProps {
  id: string;
  date: string;
  dayType: DayType;
  breakfast?: MealRate;
  lunch?: MealRate;
  dinner?: MealRate;
  dayRate: string;
  reimbursableRate?: string;
  location: string;
  countryIsoCode: string;
}

export const usePerDiemItemCalculation = () => {
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { canUsePerDiemReimbursement } = useReimbursement();
  const { transactionAmountFormatter } = useMoneyFormatter();

  const formatPerDiemDay = useFormatPerDiemDay();

  const skipQuery = !reimbursementId || !canUsePerDiemReimbursement;

  const { data, loading } = usePerDiemItemCalculationQuery({
    variables: { filters: { reimbursementCaseId: reimbursementId } },
    fetchPolicy: 'network-only',
    skip: skipQuery,
  });

  const items = data?.perDiemItemCalculation ?? [];

  const perDiemItem = useMemo(
    () =>
      items.find(
        (item): item is PerDiemItem => item.__typename === 'PerDiemItem'
      ),
    [items]
  );

  const perDiemDayCalculationData = useMemo(() => {
    if (!perDiemItem?.days) return [];

    return perDiemItem.days.map(formatPerDiemDay);
  }, [formatPerDiemDay, perDiemItem?.days]);

  const { totalAmount } = perDiemItem ?? {};

  const formattedTotalReimbursableRate = totalAmount?.amount
    ? transactionAmountFormatter({
        value: totalAmount.amount ?? 0,
        currency: totalAmount?.currency ?? DocumentCurrency.Eur,
        precision: totalAmount?.precision ?? 2,
      })
    : undefined;

  return {
    isLoading: loading,
    perDiemDayCalculationData,
    totalReimbursableRate: formattedTotalReimbursableRate,
  };
};
