import { gql } from '@apollo/client';
import {
  expenseItemArchivedEventFragment,
  expenseItemBookingAddedEventFragment,
  expenseItemBookingRemovedEventFragment,
  expenseItemBookingUpdatedEventFragment,
  expenseItemExpenseDateUpdatedEventFragment,
  expenseItemFileAddedEventFragment,
  expenseItemFileRemovedEventFragment,
  expenseItemHospitalityExternalGuestsUpdatedEventFragment,
  expenseItemHospitalityInternalGuestsUpdatedEventFragment,
  expenseItemHospitalityLocationUpdatedEventFragment,
  expenseItemHospitalityReceiptAmountUpdatedEventFragment,
  expenseItemHospitalityTipAmountUpdatedEventFragment,
  expenseItemInvoiceNumberUpdatedEventFragment,
  expenseItemTitleUpdatedEventFragment,
  expenseItemTotalAmountUpdatedEventFragment,
  reimbursementCaseAmendmentRequestedEventFragment,
  reimbursementCaseApprovedEventFragment,
  reimbursementCaseCommentAddedEventFragment,
  reimbursementCaseCreatedEventFragment,
  reimbursementCaseItemAddedEventFragment,
  reimbursementCaseItemExcludedEventFragment,
  reimbursementCaseItemExportStatusUpdatedEventFragment,
  reimbursementCaseItemIncludedEventFragment,
  reimbursementCaseItemRemovedEventFragment,
  reimbursementCaseNoteUpdatedEventFragment,
  reimbursementCaseResetApprovalEventFragment,
  reimbursementCaseSubmittedForReviewEventFragment,
  reimbursementCaseTargetMembershipUpdatedEventFragment,
  reimbursementCaseTitleUpdatedEventFragment,
  reimbursementCaseSubmittedForApprovalEventFragment,
  reimbursementCaseTriggeredWorkflowEventFragment,
  reimbursementCaseWorkflowStepApprovedEventFragment,
  reimbursementCaseWorkflowStepRejectedEventFragment,
  reimbursementCaseRejectedEventFragment,
  expenseItemGeneralDataExtractedEventFragment,
  expenseItemHospitalityDataExtractedEventFragment,
} from './fragments';

export const reimbursementCaseDataFragment = gql`
  fragment ReimbursementCaseData on ReimbursementCase {
    id
    organizationId
    createdAt
    updatedAt
    status
    createTransfer
    contact {
      contactId
      iban
    }
    isPaid
    paidAt
    title
    note
    createdByMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    targetMembership {
      id
      firstName
      lastName
      avatarUrl
    }
    totalAmount {
      amount
      currency
      precision
    }
    advancedAccountingEnabled
    suggestedCostCenter {
      id
      readableName
    }
    suggestedCostObject {
      id
      readableName
    }
    suggestedGeneralLedgerAccount {
      id
      readableName
    }
    errors {
      field
      errors
    }
  }
`;

export const archiveReimbursementCasesListItemDataFragment = gql`
  fragment ArchiveReimbursementCasesListItemData on ArchiveReimbursementCasesListItem {
    id
    organizationId
    createdAt
    status
    title
    totalAmount {
      amount
      currency
      precision
    }
  }
`;

export const reimbursementCaseByIdQuery = gql`
  query reimbursementCaseById($id: ID!) {
    reimbursementCaseById(id: $id) {
      ...ReimbursementCaseData
    }
  }
  ${reimbursementCaseDataFragment}
`;

export const archiveReimbursementCasesCountQuery = gql`
  query archiveReimbursementCasesCount(
    $filters: ReimbursementCasesFilterInput
  ) {
    archiveReimbursementCasesCount(filters: $filters) {
      totalCount
    }
  }
`;

export const inboxReimbursementCasesCountQuery = gql`
  query inboxReimbursementCasesCount {
    inboxReimbursementCasesCount {
      totalCount
    }
  }
`;

export const reimbursementItemsQuery = gql`
  query reimbursementItems($filters: ReimbursementItemFiltersInput!) {
    reimbursementItems(filters: $filters) {
      ... on GeneralExpenseItem {
        id
        reimbursementCaseId
        createdAt
        updatedAt
        type
        status
        title
        expenseDate
        updatedAt
        invoiceNumber
        files {
          id
          url
          name
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        extractedDataAccepted
        extractedData {
          expenseDate
          invoiceNumber
          sourceFile {
            id
            url
            name
          }
          totalAmount {
            amount
            currency
            precision
          }
        }
      }

      ... on HospitalityExpenseItem {
        id
        reimbursementCaseId
        createdAt
        updatedAt
        type
        status
        title
        expenseDate
        location
        invoiceNumber
        files {
          id
          url
        }
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        internalGuests
        externalGuests
        receiptAmount {
          amount
          currency
          precision
        }
        tipAmount {
          amount
          currency
          precision
        }
        extractedDataAccepted
        extractedData {
          expenseDate
          invoiceNumber
          location
          sourceFile {
            id
            url
            name
          }
          receiptAmount {
            amount
            currency
            precision
          }
          tipAmount {
            amount
            currency
            precision
          }
        }
      }

      ... on PerDiemItem {
        id
        type
        status
        currentStep
        totalAmount {
          amount
          currency
          precision
        }
        segments {
          id
          startAt
          endAt
          location {
            id
          }
        }
        days {
          id
          date
          providedBreakfast
          providedLunch
          providedDinner
        }
      }
    }
  }
`;

export const reimbursementItemsListQuery = gql`
  query reimbursementItemsList($filters: ReimbursementItemFiltersInput!) {
    reimbursementItemsList: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsList", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        updatedAt
        extractedDataAccepted
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        updatedAt
        extractedDataAccepted
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
      }

      ... on PerDiemItem {
        id
        type
        status
        currentStep
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
  }
`;

export const reimbursementsCursorBasedNavigationQuery = gql`
  query reimbursementsCursorBasedNavigation(
    $next: String
    $filters: ReimbursementCasesFilterInput
    $sorts: ReimbursementCasesSortInput
    $searchInput: SearchInput
    $previous: String
    $search: String
  ) {
    prevReimbursement: archiveReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      previous: $previous
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
    nextReimbursement: archiveReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      next: $next
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
  }
`;

export const reimbursementsInboxNavigationQuery = gql`
  query reimbursementsInboxNavigation(
    $next: String
    $filters: ReimbursementCasesFilterInput
    $sorts: ReimbursementCasesSortInput
    $searchInput: SearchInput
    $previous: String
    $search: String
  ) {
    prevReimbursement: inboxReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      previous: $previous
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
    nextReimbursement: inboxReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      next: $next
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
  }
`;

export const reimbursementsApprovalsNavigationQuery = gql`
  query reimbursementsApprovalsNavigation(
    $next: String
    $filters: ReimbursementCasesFilterInput
    $sorts: ReimbursementCasesSortInput
    $searchInput: SearchInput
    $previous: String
    $search: String
  ) {
    prevReimbursement: approvalReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      previous: $previous
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
    nextReimbursement: approvalReimbursementCases(
      filters: $filters
      sorts: $sorts
      search: $search
      searchInput: $searchInput
      limit: 1
      next: $next
    ) {
      edges {
        cursor
        node {
          id
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor: nextPageCursor
      }
    }
  }
`;

export const reimbursementItemsSplitBookingsQuery = gql`
  query reimbursementItemsSplitBookings(
    $filters: ReimbursementItemFiltersInput!
  ) {
    reimbursementItemsSplitBookings: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsSplitBookings", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              taxCode
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }

      ... on PerDiemItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              taxCode
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }
    }
  }
`;

export const reimbursementItemsSplitBookingsInitialSnapshotQuery = gql`
  query reimbursementItemsSplitBookingsInitialSnapshot(
    $filters: ReimbursementItemFiltersInput!
  ) {
    reimbursementItemsSplitBookingsInitialSnapshot: reimbursementItems(
      filters: $filters
    )
      @connection(
        key: "reimbursementItemsSplitBookingsInitialSnapshot"
        filter: ["filters"]
      ) {
      ... on GeneralExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              taxCode
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }

      ... on PerDiemItem {
        id
        type
        status
        title
        totalAmount {
          amount
          currency
          precision
        }
        errors {
          field
          errors
        }
        bookings {
          id
          artistSocialInsuranceCode
          costCenter {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          costObject {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          extraCostInfo {
            confidence
            source
            value {
              id
              name
              code
              readableName
            }
          }
          generalLedgerAccount {
            confidence
            source
            value {
              id
              name
              accountCode
              readableName
            }
          }
          bookingKey {
            confidence
            source
            value {
              id
              taxCode
              readableName
            }
          }
          amount {
            value
            confidence
            source
          }
          vatRate {
            value
            confidence
            source
          }
          note {
            value
            confidence
            source
          }
          postingText
        }
      }
    }
  }
`;

export const reimbursementHistoryQuery = gql`
  query getReimbursementHistory($id: ID!) {
    reimbursementCaseById(id: $id)
      @connection(key: "getReimbursementHistory", filter: ["id"]) {
      id
      timeline {
        __typename
        id
        createdAt
        ...ReimbursementCaseTitleUpdatedEventData
        ...ExpenseItemTitleUpdatedEventData
        ...ReimbursementCaseAmendmentRequestedEventData
        ...ReimbursementCaseApprovedEventData
        ...ReimbursementCaseCreatedEventData
        ...ReimbursementCaseSubmittedForReviewEventData
        ...ReimbursementCaseTargetMembershipUpdatedEventData
        ...ReimbursementCaseNoteUpdatedEventData
        ...ExpenseItemTotalAmountUpdatedEventData
        ...ExpenseItemFileAddedEventData
        ...ExpenseItemFileRemovedEventData
        ...ExpenseItemExpenseDateUpdatedEventData
        ...ReimbursementCaseCommentAddedEventData
        ...ExpenseItemArchivedEventData
        ...ExpenseItemHospitalityExternalGuestsUpdatedEventData
        ...ExpenseItemHospitalityInternalGuestsUpdatedEventData
        ...ExpenseItemHospitalityLocationUpdatedEventData
        ...ExpenseItemHospitalityReceiptAmountUpdatedEventData
        ...ExpenseItemHospitalityTipAmountUpdatedEventData
        ...ExpenseItemBookingAddedEventData
        ...ExpenseItemBookingRemovedEventData
        ...ExpenseItemBookingUpdatedEventData
        ...ReimbursementCaseResetApprovalEventData
        ...ReimbursementCaseItemAddedEventData
        ...ReimbursementCaseItemRemovedEventData
        ...ReimbursementCaseItemIncludedEventData
        ...ReimbursementCaseItemExcludedEventData
        ...ExpenseItemInvoiceNumberUpdatedEventData
        ...ReimbursementCaseItemExportStatusUpdatedEventData
        ...ReimbursementCaseSubmittedForApprovalEventData
        ...ReimbursementCaseWorkflowStepRejectedEventData
        ...ReimbursementCaseWorkflowStepApprovedEventData
        ...ReimbursementCaseTriggeredWorkflowEventData
        ...ReimbursementCaseRejectedEventData
        ...ExpenseItemGeneralDataExtractedEventData
        ...ExpenseItemHospitalityDataExtractedEventData
      }
    }
  }
  ${reimbursementCaseTitleUpdatedEventFragment}
  ${expenseItemTitleUpdatedEventFragment}
  ${reimbursementCaseAmendmentRequestedEventFragment}
  ${reimbursementCaseApprovedEventFragment}
  ${reimbursementCaseCreatedEventFragment}
  ${reimbursementCaseSubmittedForReviewEventFragment}
  ${reimbursementCaseTargetMembershipUpdatedEventFragment}
  ${reimbursementCaseNoteUpdatedEventFragment}
  ${expenseItemTotalAmountUpdatedEventFragment}
  ${expenseItemFileAddedEventFragment}
  ${expenseItemFileRemovedEventFragment}
  ${expenseItemExpenseDateUpdatedEventFragment}
  ${reimbursementCaseCommentAddedEventFragment}
  ${expenseItemArchivedEventFragment}
  ${expenseItemHospitalityExternalGuestsUpdatedEventFragment}
  ${expenseItemHospitalityInternalGuestsUpdatedEventFragment}
  ${expenseItemHospitalityLocationUpdatedEventFragment}
  ${expenseItemHospitalityReceiptAmountUpdatedEventFragment}
  ${expenseItemHospitalityTipAmountUpdatedEventFragment}
  ${expenseItemBookingAddedEventFragment}
  ${expenseItemBookingRemovedEventFragment}
  ${expenseItemBookingUpdatedEventFragment}
  ${reimbursementCaseResetApprovalEventFragment}
  ${reimbursementCaseItemAddedEventFragment}
  ${reimbursementCaseItemRemovedEventFragment}
  ${reimbursementCaseItemIncludedEventFragment}
  ${reimbursementCaseItemExcludedEventFragment}
  ${expenseItemInvoiceNumberUpdatedEventFragment}
  ${reimbursementCaseItemExportStatusUpdatedEventFragment}
  ${reimbursementCaseSubmittedForApprovalEventFragment}
  ${reimbursementCaseTriggeredWorkflowEventFragment}
  ${reimbursementCaseWorkflowStepApprovedEventFragment}
  ${reimbursementCaseWorkflowStepRejectedEventFragment}
  ${reimbursementCaseRejectedEventFragment}
  ${expenseItemGeneralDataExtractedEventFragment}
  ${expenseItemHospitalityDataExtractedEventFragment}
`;

export const reimbursementCaseErrorsByIdQuery = gql`
  query reimbursementCaseErrorsById($id: ID!) {
    reimbursementCaseErrorsById: reimbursementCaseById(id: $id) {
      id
      errors {
        field
        errors
      }
    }
  }
`;

export const reimbursementItemsErrorsQuery = gql`
  query reimbursementItemsErrors($filters: ReimbursementItemFiltersInput!) {
    reimbursementItemsErrors: reimbursementItems(filters: $filters)
      @connection(key: "reimbursementItemsErrors", filter: ["filters"]) {
      ... on GeneralExpenseItem {
        id
        type
        title
        status
        errors {
          field
          errors
        }
      }

      ... on HospitalityExpenseItem {
        id
        type
        title
        status
        errors {
          field
          errors
        }
      }

      ... on PerDiemItem {
        id
        type
        title
        status
        errors {
          field
          errors
        }
      }
    }
  }
`;

export const reimbursementCaseWorkflowByIdQuery = gql`
  query reimbursementCaseWorkflow($id: ID!) {
    reimbursementCaseWorkflow: reimbursementCaseById(id: $id) {
      workflow {
        id
        description
        name
        workflowTemplate {
          id
          name
          steps {
            resolvers {
              id
              firstName
              lastName
              avatarUrl
            }
          }
        }
        currentStep {
          id
          approvers {
            id
            firstName
            lastName
            avatarUrl
          }
        }
        steps {
          id
          type
          name
          resolution {
            type
            comment
            approvers {
              id
              firstName
              lastName
              avatarUrl
            }
          }
          approvers {
            id
            firstName
            lastName
            avatarUrl
            substitute {
              id
              firstName
              lastName
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

export const reimbursementCasesSummaryQuery = gql`
  query getReimbursementCasesSummary($targetMembershipIds: [String!]!) {
    reimbursementCasesSummary(
      filters: { targetMembershipIds: $targetMembershipIds }
    ) {
      ...ReimbursementCasesSummaryFragment
    }
  }
  fragment ReimbursementCasesSummaryFragment on ReimbursementCasesSummary {
    draft {
      totalCount
      totalAmount {
        amount
        currency
        precision
      }
      withoutInvoiceCount
    }
    open {
      totalCount
      totalAmount {
        amount
        currency
        precision
      }
      olderThan14DaysCount
    }
    closed {
      totalCount
      last30DaysCount
    }
    approved {
      approvedInLast30DaysCount
      approvedInLast30DaysAmount {
        amount
        currency
        precision
      }
    }
  }
`;

export const aggregatedReimbursementCasesQuery = gql`
  query getAggregatedReimbursementCases(
    $targetMembershipIds: [String!]!
    $draftStatuses: [ReimbursementCaseStatus!]!
    $openStatuses: [ReimbursementCaseStatus!]!
    $closedStatuses: [ReimbursementCaseStatus!]!
    $sortOrder: ReimbursementCasesSortOrder!
    $lastClosedAt: ReimbursementCasesDateFilter!
  ) {
    draftCases: archiveReimbursementCases(
      limit: 6
      filters: {
        statuses: $draftStatuses
        targetMembershipIds: $targetMembershipIds
      }
      sorts: { createdAt: $sortOrder }
    ) {
      edges {
        node {
          id
          title
          status
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
    openCases: archiveReimbursementCases(
      limit: 6
      filters: {
        statuses: $openStatuses
        targetMembershipIds: $targetMembershipIds
      }
      sorts: { createdAt: $sortOrder }
    ) {
      edges {
        node {
          id
          title
          status
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
    closedCases: archiveReimbursementCases(
      limit: 6
      filters: {
        statuses: $closedStatuses
        targetMembershipIds: $targetMembershipIds
        lastClosedAt: $lastClosedAt
      }
      sorts: { lastClosedAt: $sortOrder }
    ) {
      edges {
        node {
          id
          title
          status
          totalAmount {
            amount
            currency
            precision
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;

export const membershipQuery = gql`
  query membershipById($membershipId: String!) {
    membership(membershipId: $membershipId) {
      id
      name
      firstName
      lastName
      roles
      locale
      email
      avatarUrl
      status
      createdTimestamp
      username
      paymentInfo {
        iban
        swiftCode
      }
      membershipRoles {
        __typename
        id
        name
        description
        isArchived
        isBuiltinRole
        isLegacyRole
      }
    }
  }
`;

export const perDiemLocationsQuery = gql`
  query perDiemLocations($input: PerDiemLocationsInput!) {
    perDiemLocations(input: $input) {
      locations {
        id
        countryIsoCode
        region
        country
        location
      }
    }
  }
`;

export const perDiemItemDetailsQuery = gql`
  query perDiemItemDetails($filters: ReimbursementItemFiltersInput!) {
    perDiemItemDetails: reimbursementItems(filters: $filters)
      @connection(key: "perDiemItemDetails", filter: ["filters"]) {
      ... on PerDiemItem {
        __typename
        id
        status
        currentStep
        totalAmount {
          amount
          currency
          precision
        }
        segments {
          id
          startAt
          endAt
          location {
            id
            countryIsoCode
            country
            location
          }
        }
      }
    }
  }
`;

export const perDiemItemCalculationQuery = gql`
  query perDiemItemCalculation($filters: ReimbursementItemFiltersInput!) {
    perDiemItemCalculation: reimbursementItems(filters: $filters)
      @connection(key: "perDiemItemCalculation", filter: ["filters"]) {
      ... on PerDiemItem {
        __typename
        id
        status
        days {
          id
          date
          isFullDay
          dayRate {
            amount
            currency
            precision
          }
          reimbursableRate {
            amount
            currency
            precision
          }
          meals {
            breakfast {
              provided
              rateRatio
              deduction {
                amount
                currency
                precision
              }
            }
            lunch {
              provided
              rateRatio
              deduction {
                amount
                currency
                precision
              }
            }
            dinner {
              provided
              rateRatio
              deduction {
                amount
                currency
                precision
              }
            }
          }
          location {
            id
            countryIsoCode
            country
            location
          }
        }
        totalAmount {
          amount
          currency
          precision
        }
      }
    }
  }
`;
