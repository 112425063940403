import { Box, Flex, TabPanel, Tabs } from '@candisio/design-system';
import { useTabs } from 'components/Tabs/useTabs';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useState } from 'react';
import { BatchFastApprove } from 'views/AppContainer/components/Header/components/QaUtils/BatchFastApprove/BatchFastApprove';
import { GraphiQL } from 'views/AppContainer/components/Header/components/QaUtils/GraphiQL/GraphiQL';
import { auditorEmails } from 'views/AppContainer/components/Header/components/QaUtils/utils';
import { BatchMockTransaction } from './BatchMockTransaction';
import { PromoPreferences } from './PromoPreferences/PromoPreferences';
import { FeatureFlags } from './OrgConfig/containers/FeatureFlags';
import { Overview } from './OrgConfig/containers/Overview';

enum QA_TAB {
  APPROVE = 'APPROVE',
  FFS = 'FFS',
  TRANSACTION = 'TRANSACTION',
  PROMO_PREFERENCES = 'PROMO_PREFERENCES',
  GRAPHI_QL = 'GRAPHI_QL',
}

export const QaUtilsModalContent = () => {
  const user = useCurrentUser();

  const [tab, setTab] = useState<QA_TAB>(QA_TAB.FFS);

  const defaultTabs = [
    {
      key: QA_TAB.FFS,
      title: 'Org Config',
      children: (
        <Flex justifyContent="space-between" gap="space24">
          <Overview />
          <FeatureFlags />
        </Flex>
      ),
    },
    {
      key: QA_TAB.APPROVE,
      title: 'Batch Fast Approve',
      children: <BatchFastApprove />,
    },
    {
      key: QA_TAB.TRANSACTION,
      title: 'Batch Mock Transactions',
      children: <BatchMockTransaction />,
    },
    {
      key: QA_TAB.PROMO_PREFERENCES,
      title: 'Promotions / Tours',
      children: <PromoPreferences />,
    },
    {
      key: QA_TAB.GRAPHI_QL,
      title: 'GraphiQL',
      children: <GraphiQL />,
    },
  ];

  const auditorTabs = [
    {
      key: QA_TAB.TRANSACTION,
      title: 'Batch Mock Transactions',
      children: <BatchMockTransaction />,
    },
    {
      key: QA_TAB.GRAPHI_QL,
      title: 'GraphiQL',
      children: <GraphiQL />,
    },
  ];

  const applyAuditorTabs = user?.email && auditorEmails.includes(user?.email);

  const { tabPanelProps, tabsProps } = useTabs({
    selectedKey: tab,
    items: !user?.email ? [] : applyAuditorTabs ? auditorTabs : defaultTabs,
    onSelectionChange: key => {
      setTab(key as QA_TAB);
    },
  });

  const { key: tabPanelKey, ...restTabPanelProps } = tabPanelProps;

  return (
    <>
      <Box borderBottom="1px solid gray300" width="100%">
        <Tabs {...tabsProps} size="small" />
      </Box>
      <TabPanel key={tabPanelKey} {...restTabPanelProps} overflow="hidden" />
    </>
  );
};
