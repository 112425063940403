import { Grid } from '@candisio/design-system';
import {
  StickyCardRefProvider,
  useStickyCardRefContext,
} from 'providers/StickyCardRefProvider';
import { ReactNode, useMemo } from 'react';
import { BookingsFormContextProvider } from 'views/DocumentDetails/BookingsFormContext';
import { BoxWrapper } from './styles';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { ResizableSection } from './ResizableSection/ResizableSection';
import { useDocumentViewStore } from './DetailsLayoutContext';

export const DetailsLayout = ({
  children,
  leftSection,
  middleSection,
  rightSection,
}: {
  children?: ReactNode;
  leftSection: ReactNode;
  middleSection: ReactNode;
  rightSection: ReactNode;
}) => {
  const cardRef = useStickyCardRefContext();

  const { isComparisonView } = useComparisonView();
  const { isBookingsFormOpen } = useDocumentViewStore();
  const templateColumns = useMemo(() => {
    if (isComparisonView) {
      return 'auto 2fr 0fr';
    }
    if (isBookingsFormOpen) {
      return 'auto 2fr auto';
    }
    return 'auto 2fr 1fr';
  }, [isComparisonView, isBookingsFormOpen]);

  return (
    <Grid
      // @ts-ignore
      style={{ containerType: 'size', willChange: 'grid-template-columns' }}
      height="100%"
      paddingBottom="0"
      paddingRight="0"
      paddingLeft="space16"
      overflow="hidden"
      alignItems="start"
      gap="space16"
      transition=".3s"
      templateColumns={templateColumns}
      data-cy="details-layout"
    >
      <BookingsFormContextProvider>
        <Grid autoFlow="column" overflow="hidden" height="100%">
          {leftSection}
        </Grid>
        {middleSection}
        <StickyCardRefProvider>
          <ResizableSection>
            <BoxWrapper data-scroll-padding={cardRef?.current?.offsetHeight}>
              {rightSection}
            </BoxWrapper>
          </ResizableSection>
        </StickyCardRefProvider>
        {children}
      </BookingsFormContextProvider>
    </Grid>
  );
};
