import {
  flexRender,
  getFilteredRowModel,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BookingsFormProps } from '../types';
import { useColumns } from '../hooks/useColumns';
import styles from './BookingsTable.module.css';
import { TableFormRow } from '../schema/bookingsFormSchema';
import { useEffect } from 'react';
import { AddNewRow } from './AddNewRow';
import clsx from 'clsx';
import { useColumnVisibility } from '../hooks/useColumnVisibility';

type BookingsTableProps = {
  initialValues: BookingsFormProps['initialValues'];
  data: TableFormRow[];
  isReadOnly?: boolean;
  appendRow?: () => void;
};

export const BookingsTable = ({
  initialValues,
  data,
  isReadOnly,
  appendRow,
}: BookingsTableProps) => {
  const { defaultColumn, columns } = useColumns({ initialValues });
  // TODO will delete this effect here for debugging purposes
  useEffect(() => {
    console.log({ data });
  }, [data]);

  const columnVisibility = useColumnVisibility({
    dataLength: data.length,
    isReadOnly,
  });

  const { getTotalSize, getRowCount, getHeaderGroups, getRowModel } =
    useReactTable({
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      defaultColumn,
      data,
      // this is important for row delete/swap to work correctly
      getRowId: (originalRow, index) =>
        originalRow.bookingId ?? index.toString(),
      state: {
        columnVisibility,
      },
    });

  return (
    <table
      className={styles.table}
      style={{
        width: getTotalSize(),
        height: getRowCount() * 42, // cell height + border TODO will add as constant or sth
      }}
    >
      <thead>
        {getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isActionHeader = header.id === 'actions';
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                  }}
                  className={clsx({
                    [styles.actionColumnHeader]: isActionHeader,
                  })}
                >
                  {header.isPlaceholder || isActionHeader ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => {
              const isActionCell = cell.column.id === 'actions';
              return (
                <td
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                  }}
                  className={clsx({
                    [styles.actionCell]: isActionCell,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
        <tr className={styles.addNewBtnWrapper}>
          <td colSpan={columns.length}>
            <AddNewRow
              disabled={isReadOnly}
              onClick={appendRow}
              className={styles.addNewBtn}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
