import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import {
  getRoundingDifference,
  grossToNet,
  sumBookings,
} from 'containers/SplitBookings/toolkit/utils';
import { useSap } from 'orgConfig/sap';
import { useCallback } from 'react';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { useSetProcessingFormMetadata } from 'components/Form/ProcessingForm/ProcessingFormSetMetadataContext';
import { useUpdateProcessingFormField } from 'components/Form/ProcessingForm/useUpdateProcessingFormField';
import { useFormContext } from 'react-hook-form';
import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import { useGetBookingTransformers } from './useGetBookingTransformers';
import { useBookingsFieldArrayContext } from 'components/Form/ProcessingForm/BookingsFieldArrayContext';
import { BookingsFormValues } from '../schema/bookingsFormSchema';
import { bookingWithNetAmount } from '../utils/amountUtils';
import { useDocumentViewStore } from 'components/Layouts/DetailsLayoutContext';

type UseGetBookingsSubmitActionsProps = {
  initialCurrency: string;
  initialGrossAmount: number;
  initialBookingsLength: number;
  hasTransactions: boolean;
  isGoodsPurchaseOrderLinked: boolean;
  onAcceptBookings?: () => void;
};

export const useGetBookingsSubmitActions = ({
  initialCurrency,
  initialGrossAmount,
  initialBookingsLength,
  hasTransactions,
  isGoodsPurchaseOrderLinked,
  onAcceptBookings,
}: UseGetBookingsSubmitActionsProps) => {
  const { shouldUseSapNetAmount, shouldUseSapPurchaseOrder } = useSap();
  const { setIsBookingsFormOpen } = useDocumentViewStore();
  const processingForm = useFormContext<ProcessingFormValues>();
  const bookingsFieldArray = useBookingsFieldArrayContext();
  // This flow copied from the current split bookings form container will be refactored later
  const { toProcessingFormBooking } = useGetBookingTransformers();
  const setProcessingFormMetadata = useSetProcessingFormMetadata();
  const updateProcessingFormField = useUpdateProcessingFormField();

  const handleAcceptBookings = useCallback(
    (bookingsFormValues: BookingsFormValues) => {
      const currentBookings = bookingsFormValues.bookings;
      console.log({ currentBookings });
      const currentBookingsLength = currentBookings.length;
      const prevBookingIsMultipleSplit = initialBookingsLength > 1;
      const isSapPackageFreightPrevBookings =
        shouldUseSapPurchaseOrder && initialBookingsLength > 0;
      const currentBookingIsMultipleSplit = currentBookingsLength > 1;

      const isSapPackageFreightCurrentBookings =
        shouldUseSapPurchaseOrder && currentBookingsLength > 0;

      const isCreatingMultipleSplitsFromSingleSplit =
        (!isSapPackageFreightPrevBookings &&
          isSapPackageFreightCurrentBookings) ||
        (!prevBookingIsMultipleSplit && currentBookingIsMultipleSplit);
      const bookingsWithoutId = currentBookings.map(
        ({ bookingId, ...rest }) => rest
      );
      const bookingsWithId = currentBookings;
      const normalizedBookings = shouldUseSapPurchaseOrder
        ? bookingsWithId
        : isCreatingMultipleSplitsFromSingleSplit
          ? bookingsWithoutId
          : bookingsWithId;

      const roundingDifference = getRoundingDifference({
        grossAmount: bookingsFormValues.grossAmount ?? 0,
        bookings: currentBookings.map(bookingWithNetAmount),
      });
      const modifiedBookings =
        roundingDifference && !shouldUseSapNetAmount
          ? normalizedBookings.map((booking, index) => {
              if (index === normalizedBookings.length - 1) {
                const updatedAmount =
                  (booking.amount ?? 0) + roundingDifference;

                const netAmount =
                  booking.taxPresentation === TaxPresentation.Gross
                    ? grossToNet(updatedAmount, booking.vatRate ?? 0)
                    : updatedAmount;

                const taxAmount = roundToCurrencyPrecision(
                  booking.taxPresentation === TaxPresentation.Gross
                    ? updatedAmount - netAmount
                    : (netAmount * (booking.vatRate ?? 0)) / 100
                );

                return {
                  ...booking,
                  amount: updatedAmount,
                  taxAmount,
                  netAmount,
                };
              } else {
                return booking;
              }
            })
          : normalizedBookings;
      const values = {
        ...bookingsFormValues,
        bookings: modifiedBookings,
      };
      if (processingForm) {
        const newBookings = values.bookings.map(toProcessingFormBooking);
        console.log({ newBookings });

        const sumOfSplitAmounts = sumBookings(newBookings);
        const difference = roundToCurrencyPrecision(
          (values.grossAmount ?? 0) - sumOfSplitAmounts
        );

        if (difference && !shouldUseSapNetAmount) {
          newBookings[newBookings.length - 1].amount =
            (newBookings[newBookings.length - 1].amount ?? 0) + difference;
        }

        if (values.currency !== initialCurrency) {
          updateProcessingFormField?.(
            'currency',
            values.currency as string,
            undefined
          );
        }

        const shouldSumNetAmounts =
          (values.bookings.length > 1 && shouldUseSapNetAmount) ||
          (values.bookings.length === 1 && isGoodsPurchaseOrderLinked);

        if (shouldSumNetAmounts) {
          const totalTaxAmount = values.bookings.reduce(
            (accumulator, currentValue) =>
              accumulator + (currentValue.taxAmount ?? 0),
            0
          );

          const totalNetAmount = values.bookings.reduce(
            (accumulator, currentValue) =>
              accumulator + (currentValue.netAmount ?? 0),
            0
          );

          updateProcessingFormField?.('taxAmount', totalTaxAmount, undefined);
          updateProcessingFormField?.('netAmount', totalNetAmount, undefined);
        }

        // Using setValue on the bookings field array causes unpredictable results
        // The recommended way to replace the values is to use the replace method:
        // https://react-hook-form.com/docs/useform/setvalue
        bookingsFieldArray?.replace(newBookings);
        void processingForm.trigger('bookings'); // TODO do we need this? !!!
        void processingForm.trigger('grossAmount');
        if (values.grossAmount !== initialGrossAmount) {
          updateProcessingFormField?.(
            'grossAmount',
            values.grossAmount as number,
            undefined
          );
        }

        setProcessingFormMetadata('bookings', undefined);
        if (hasTransactions) {
          // if we have a linked transaction, due date field needs to keep
          // 'transaction' as its source
          // @TODO this should be handled in a more robust way
          setProcessingFormMetadata('bookings.0.dueDate', {
            confidence: 1,
            source: 'transaction',
          });
        }
      }
      setIsBookingsFormOpen(false);
      onAcceptBookings?.();
    },
    [
      isGoodsPurchaseOrderLinked,
      shouldUseSapNetAmount,
      initialCurrency,
      initialGrossAmount,
      hasTransactions,
      bookingsFieldArray,
      initialBookingsLength,
      shouldUseSapPurchaseOrder,
      onAcceptBookings,
      setProcessingFormMetadata,
      updateProcessingFormField,
      toProcessingFormBooking,
      setIsBookingsFormOpen,
      processingForm,
    ]
  );

  return { handleAcceptBookings };
};
