import styles from './PerDiem.module.css';
import { PerDiemItemDetails } from 'views/Reimbursement/hooks/usePerDiemItemDetails';
import {
  Button,
  ButtonProps,
  Skeleton,
  usePopover,
} from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { LocationSummary } from './LocationSummary';
import { TruncatedText } from '@candisio/design-system/next';
import { ConfirmationPopover } from 'components/ConfirmationPopover/ConfirmationPopover';

interface PerDiemSegmentSummaryProps {
  isLoading: boolean;
  perDiemItemDetails?: PerDiemItemDetails;
  isPerDiemFormEditable: boolean;
  isEditSegmentPending: boolean;
  onEditPerDiemSegment: () => Promise<void>;
}

export const PerDiemSegmentSummary = ({
  isLoading,
  perDiemItemDetails,
  isPerDiemFormEditable,
  isEditSegmentPending,
  onEditPerDiemSegment,
}: PerDiemSegmentSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { isOpen, triggerProps, triggerRef, popoverProps, popoverRef, close } =
    usePopover();

  const confirmButtonProps: ButtonProps = {
    color: 'blue',
    loading: isEditSegmentPending,
    disabled: isEditSegmentPending,
  };

  const handleClick = () => {
    onEditPerDiemSegment();
    close();
  };

  if (isLoading) {
    return (
      <div className="grid gap-3 border-b border-gray-250 pb-4">
        <span className={styles.segmentTitle}>
          {t('reimbursementView.middleSection.form.perDiem.segmentDesc')}
        </span>
        <div className={styles.segmentSummaryContainer}>
          <Skeleton height="80px" width="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-3 border-b border-gray-250 pb-4">
      <span className={styles.segmentTitle}>
        {t('reimbursementView.middleSection.form.perDiem.segmentDesc')}
      </span>
      <div
        className={styles.segmentSummaryContainer}
        data-editable={isPerDiemFormEditable}
      >
        <div className="grid gap-2">
          {perDiemItemDetails?.segments.map(({ id, location }) => (
            <LocationSummary key={id} {...location} />
          ))}
        </div>
        {isPerDiemFormEditable && (
          <>
            <Button
              icon="edit"
              size="small"
              variant="tertiary"
              alignSelf="self-end"
              {...triggerProps}
              ref={triggerRef}
              className={styles.editAction}
              loading={isEditSegmentPending}
              disabled={isEditSegmentPending}
            >
              <TruncatedText>
                {t('reimbursementView.middleSection.form.perDiem.editSegment')}
              </TruncatedText>
            </Button>
            {isOpen && (
              <ConfirmationPopover
                title={t(
                  'reimbursementView.middleSection.form.perDiem.days.confirmationPopover.description'
                )}
                confirmText={t(
                  'reimbursementView.middleSection.form.perDiem.days.confirmationPopover.yes'
                )}
                closeText={t(
                  'reimbursementView.middleSection.form.perDiem.days.confirmationPopover.cancel'
                )}
                confirmButtonProps={confirmButtonProps}
                onConfirm={handleClick}
                onClose={close}
                {...popoverProps}
                ref={popoverRef}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
