import moment from 'moment';

export const DATE_TIME_FORMAT = {
  DATE_WITH_YEAR: 'DD. MMM. YYYY',
  DATE_WITH_TIME: 'DD. MMM., HH:mm',
} as const;

type DateTimeFormatType =
  (typeof DATE_TIME_FORMAT)[keyof typeof DATE_TIME_FORMAT];

export const formatDateTime = (
  dateTime: string,
  format: DateTimeFormatType = DATE_TIME_FORMAT.DATE_WITH_TIME
): string => {
  return moment(dateTime).format(format);
};
