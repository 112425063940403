import { Grid } from '@candisio/design-system';
import { AddTransactionsAction } from 'components/Transactions/AddTransactions/AddTransactionsModal';
import { TransactionDetailsCardSummary } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCard';
import { Transaction } from 'components/Transactions/TransactionDetailsCard/utils';
import { ReactNode } from 'react';
import { DocumentDetailsPromoContainer } from './DocumentDetailsPromo';
import {
  DocumentStatus,
  EcmDocumentStatus,
} from 'generated-types/graphql.types';

interface TransactionSectionProps {
  documentId: string;
  loading: boolean;
  transaction?: Transaction;
  canAddTransaction: boolean;
  documentStatus?: EcmDocumentStatus | DocumentStatus;
}

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Grid border="1px solid gray300" borderRadius="medium">
      {children}
    </Grid>
  );
};

export const TransactionSection = ({
  documentId,
  loading,
  transaction,
  canAddTransaction,
  documentStatus,
}: TransactionSectionProps) => {
  if (!canAddTransaction && !transaction) return null;

  return (
    <>
      <DocumentDetailsPromoContainer />
      <Wrapper>
        {canAddTransaction && <AddTransactionsAction documentId={documentId} />}
        {transaction && (
          <TransactionDetailsCardSummary
            transaction={transaction}
            loading={loading}
            documentId={documentId}
            documentStatus={documentStatus}
          />
        )}
      </Wrapper>
    </>
  );
};
