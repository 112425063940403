import { useTheme } from '@candisio/design-system';
import { CellContext } from '@tanstack/react-table';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import {
  DayType,
  PerDiemDayCalculationDataProps,
} from 'views/Reimbursement/hooks/usePerDiemItemCalculation';

const halfDayKey =
  'reimbursementView.middleSection.form.perDiem.calculationTable.halfDay';
const fullDayKey =
  'reimbursementView.middleSection.form.perDiem.calculationTable.fullDay';

interface DayRateCellProps {
  dayType: DayType;
  dayRate: string;
}

export const DayRateCell = ({
  getValue,
}: CellContext<PerDiemDayCalculationDataProps, DayRateCellProps>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { fontSizes } = useTheme();
  const { dayRate, dayType } = getValue();

  const type = dayType === 'FULL_DAY' ? t(fullDayKey) : t(halfDayKey);

  return (
    <div className="flex flex-col">
      <span className="text-green-800" style={{ fontSize: fontSizes.basic }}>
        {dayRate}
      </span>
      <span className="text-gray-500">{type}</span>
    </div>
  );
};
