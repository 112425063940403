import {
  Input,
  SearchField,
  SearchFieldProps,
  InputProps,
} from 'react-aria-components';
import styles from './power-search-input.module.css';
import { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface PowerSearchFieldProps extends PropsWithChildren {
  inputProps?: InputProps;
  searchFieldProps?: SearchFieldProps;
}

export const PowerSearchField = ({
  children,
  inputProps,
  searchFieldProps,
}: PowerSearchFieldProps) => {
  return (
    <SearchField
      type=""
      {...searchFieldProps}
      className={clsx(
        styles['power-search-input-container'],
        searchFieldProps?.className
      )}
    >
      <Input
        {...inputProps}
        className={clsx(styles['power-search-input'], inputProps?.className)}
      />
      {children}
    </SearchField>
  );
};
