import { useState } from 'react';
import {
  DateInput,
  DateSegment,
  TimeField as TimeFieldReactAria,
} from 'react-aria-components';
import { Time, parseTime } from '@internationalized/date';
import styles from './TimeInput.module.css';
import { ClearButton, StatusIndicator } from '@candisio/design-system';
import { DateSegment as DateSegmentType } from 'react-stately';

interface TimeInputProps {
  variant?: 'default' | 'error' | 'warning' | 'success';
  errorMessage?: string;
  value: string | null;
  onChange: (value: string | null) => void;
  onBlur: (value?: string | null) => void;
  hourCycle?: 24 | 12;
  isDisabled?: boolean;
  clearLabel: string;
  label?: string;
}

const mapSegment = (segment: DateSegmentType) => {
  if (segment.type === 'hour' && segment.text === '––') {
    return { ...segment, text: 'hh' };
  }

  if (segment.type === 'minute' && segment.text === '––') {
    return { ...segment, text: 'mm' };
  }

  return segment;
};

export const TimeInput = ({
  variant,
  value,
  errorMessage,
  hourCycle = 24,
  isDisabled = false,
  clearLabel,
  onChange,
  onBlur,
  label,
}: TimeInputProps) => {
  const [time, setTime] = useState<null | Time>(
    value ? parseTime(value) : null
  );

  const [inputFocused, setInputFocused] = useState(false);

  const handleClear = () => {
    setTime(null);
    onChange(null);
  };

  const handleOnChange = (value: Time | null) => {
    setTime(value);
    onChange(value ? value?.toString() : null);
  };

  const isClearable = time !== null;

  const isErrorVisible = variant === 'error' && !!errorMessage;

  const isErrorOrClearVisible = isErrorVisible || isClearable;

  return (
    <div className={styles['field-wrapper']}>
      <TimeFieldReactAria
        aria-label={label}
        isInvalid={isErrorVisible}
        onChange={handleOnChange}
        value={time}
        isDisabled={isDisabled}
        hourCycle={hourCycle}
        // XXX: Please do not set focus from JS anymore, in this case it was last resource.
        onFocus={() => setInputFocused(true)}
        onBlur={() => {
          setInputFocused(false);
          onBlur();
        }}
      >
        <DateInput className={styles['date-input']}>
          {segment => {
            return (
              <DateSegment
                className={styles['date-segment']}
                segment={mapSegment(segment)}
              />
            );
          }}
        </DateInput>
      </TimeFieldReactAria>
      {isErrorOrClearVisible && (
        <div className="flex gap-1 items-center">
          {isErrorVisible && (
            <StatusIndicator
              message={errorMessage}
              variant="error"
              showMessage={isErrorVisible && inputFocused}
              className="test-test"
            />
          )}
          {isClearable && inputFocused && (
            <ClearButton onClear={handleClear} clearLabel={clearLabel} />
          )}
        </div>
      )}
    </div>
  );
};
