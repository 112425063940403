import {
  motion,
  useMotionValue,
  useTransform,
  MotionValue,
} from 'motion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './ResizableSection.module.css';
import clamp from 'lodash/clamp';
import { useDocumentViewStore } from '../DetailsLayoutContext';
import { useSap } from 'orgConfig/sap';
import { useIsWindowResizing } from 'hooks/useIsWindowResizing';

const APPROXIMATE_MIN_WIDTH = 300;
const MAX_WIDTH = 800;
const vwToPx = (vw: number, windowWidth: number) =>
  Math.floor((vw / 100) * windowWidth);

const useResizable = () => {
  const {
    isBookingsFormOpen,
    minWidthForRightSection: minWidth,
    setMinWidthForRightSection: setMinWidth,
  } = useDocumentViewStore();

  const x = useMotionValue(0);
  const [dragOffset, setDragOffset] = useState(0);
  const isWindowResizing = useIsWindowResizing();

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const initialWidth = clamp(
      vwToPx(25, windowWidth),
      APPROXIMATE_MIN_WIDTH,
      MAX_WIDTH
    );
    // sets min width for the right section on initial render and window resize
    if (!isBookingsFormOpen && (!minWidth || isWindowResizing)) {
      setMinWidth(initialWidth);
    }
  }, [isBookingsFormOpen, minWidth, isWindowResizing, setMinWidth]);

  const handleResetWidth = useCallback(() => {
    x.set(0);
    setDragOffset(0);
  }, [x]);

  const width = useTransform(x, x => {
    if (!minWidth) return '100%';
    const newWidth = Math.floor(minWidth - x);
    return Math.floor(clamp(newWidth, minWidth, MAX_WIDTH));
  });

  return {
    x,
    width,
    dragOffset,
    isBookingsFormOpen,
    handleResetWidth,
    setDragOffset,
  };
};

const ResizeHandle = ({
  x,
  dragOffset,
  setDragOffset,
}: {
  x: MotionValue<number>;
  dragOffset: number;
  setDragOffset: (offset: number) => void;
}) => {
  return (
    <motion.div
      className={styles.resizeHandle}
      drag="x"
      dragMomentum={false}
      dragElastic={0}
      dragSnapToOrigin
      dragConstraints={{ left: -MAX_WIDTH, right: 0 }}
      onPanEnd={(_, info) => {
        setDragOffset(dragOffset + info.offset.x);
      }}
      onPan={(_, info) => {
        x.set(dragOffset + info.offset.x);
      }}
      data-cy="bookings-resize-handle"
    />
  );
};

interface ResizableSectionProps {
  children: React.ReactNode;
}

export const ResizableSection = ({ children }: ResizableSectionProps) => {
  const { shouldUseBookingsTableForm } = useSap();

  const {
    width,
    x,
    isBookingsFormOpen,
    handleResetWidth,
    dragOffset,
    setDragOffset,
  } = useResizable();

  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!isBookingsFormOpen && hasInitialized.current) {
      handleResetWidth();
    } else {
      hasInitialized.current = true;
    }
  }, [isBookingsFormOpen, handleResetWidth]);

  if (!shouldUseBookingsTableForm) {
    return <>{children}</>;
  }

  return (
    <motion.div
      className={styles.panel}
      style={{ width: width }}
      initial={false}
      data-cy="resizable-right-section"
    >
      {children}
      {isBookingsFormOpen && (
        <ResizeHandle
          x={x}
          dragOffset={dragOffset}
          setDragOffset={setDragOffset}
        />
      )}
    </motion.div>
  );
};

// TODO: max width should be dynamic on isWindowResizing
