import { Switch, Text } from '@candisio/design-system';
import { TOUR_POPOVER_IDS } from 'components/TourPopover/tourPopoverIds';
import { useSyncedPromoPreference } from 'hooks/useSyncedPromoPreference';

export const PromoPreferences = () => {
  const promoKeys = Object.values(TOUR_POPOVER_IDS);

  return (
    <div className="grid gap-2 grid-cols-[auto_1fr]">
      {promoKeys.map(promoKey => {
        return <PromoSwitch key={promoKey} promoKey={promoKey} />;
      })}
    </div>
  );
};

const PromoSwitch = ({ promoKey }: { promoKey: TOUR_POPOVER_IDS }) => {
  const { isPromotionSeen, setPromotionIsSeen } =
    useSyncedPromoPreference(promoKey);

  return (
    <>
      <Text>{promoKey}</Text>
      <Switch
        label=""
        checked={isPromotionSeen}
        onChange={isSeen => setPromotionIsSeen(isSeen)}
      />
    </>
  );
};
