import { CellContext } from '@tanstack/react-table';
import { getCountryFlag } from '../../PerDiemLocationField';
import { PerDiemDayCalculationDataProps } from 'views/Reimbursement/hooks/usePerDiemItemCalculation';
import { TruncatedText } from '@candisio/design-system/next';

interface DayCellProps {
  date: string;
  location: string;
  countryIsoCode: string;
}

export const DayCell = ({
  getValue,
}: CellContext<PerDiemDayCalculationDataProps, DayCellProps>) => {
  const { countryIsoCode, date, location } = getValue();

  return (
    <div className="flex items-center gap-3">
      <div className="flex items-center">
        {getCountryFlag({ isoCode: countryIsoCode })}
      </div>
      <div className="flex flex-col">
        <TruncatedText className="text-[0.875rem]">{date}</TruncatedText>
        <TruncatedText className="text-gray-500">{location}</TruncatedText>
      </div>
    </div>
  );
};
