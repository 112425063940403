import type { MenuProps, MenuTriggerProps } from 'react-aria-components';
import {
  Menu,
  Popover,
  MenuTrigger,
  Autocomplete,
  useFilter,
  Pressable,
} from 'react-aria-components';
import styles from '../power-search-filters.module.css';
import { Button } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { PowerSearchField } from 'components/PowerSearchField/components/PowerSearchField';

interface FilterMenuButtonProps<T>
  extends MenuProps<T>,
    Omit<MenuTriggerProps, 'children'> {}

export const FilterMenuButton = <T extends object>({
  children,
  ...props
}: FilterMenuButtonProps<T>) => {
  const { contains } = useFilter({ sensitivity: 'base' });
  const [t] = useTranslation(LOCALE_NAME_SPACE.TABLE);

  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={setIsOpen} {...props}>
      <Pressable>
        <Button
          shape="squared"
          variant="tertiary"
          icon="plus"
          iconPosition="left"
        >
          {t('powerSearchFilter.filterButton')}
        </Button>
      </Pressable>
      <Popover className={styles.filterMenuPopover}>
        <Autocomplete filter={contains}>
          <PowerSearchField
            searchFieldProps={{
              className: styles.filterMenuSearchField,
            }}
            inputProps={{
              autoFocus: true,
              'aria-label': 'Search',
              placeholder: 'Search',
              className: styles.filterSearchFieldInput,
            }}
          />
          <Menu className={styles.filterMenuItemContainer} {...props}>
            {children}
          </Menu>
        </Autocomplete>
        <div className={styles.filterFooter}>
          <Button
            shape="squared"
            variant="tertiary"
            size="small"
            color="blue"
            onClick={closeMenu}
          >
            {t('powerSearchFilter.actions.close')}
          </Button>
        </div>
      </Popover>
    </MenuTrigger>
  );
};
