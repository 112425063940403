import { FilterMenuButton } from './components/FilterMenuButton';
import { FilterItem } from './components/FilterItem';
import { FilterGroup } from './components/FilterGroup';
import { FilterGroupHeader } from './components/FilterGroupHeader';

export const PowerSearchFilters = () => {
  return (
    <FilterMenuButton>
      <FilterGroup>
        <FilterItem icon="warning">Status</FilterItem>
        <FilterItem icon="bell">Notification State</FilterItem>
        <FilterItem icon="contact">Contact</FilterItem>
        <FilterItem icon="warning">Contract Type</FilterItem>
        <FilterItem icon="menuPayments">Amount</FilterItem>
        <FilterItem icon="warning">Tag</FilterItem>
        <FilterItem icon="warning">Cost Center</FilterItem>
      </FilterGroup>
      <FilterGroup>
        <FilterGroupHeader>Date</FilterGroupHeader>
        <FilterItem icon="calendar">Start Date</FilterItem>
        <FilterItem icon="calendar">End Date</FilterItem>
      </FilterGroup>
    </FilterMenuButton>
  );
};
