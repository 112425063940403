import { createAtomicStore } from '@candis/utils';

const [useIsBookingsFormOpen, setIsBookingsFormOpen] = createAtomicStore(false);

const [useMinWidthForRightSection, setMinWidthForRightSection] =
  createAtomicStore<number | null>(null);

export const useDocumentViewStore = (): DocumentViewStoreProps => {
  const isBookingsFormOpen = useIsBookingsFormOpen();
  const minWidthForRightSection = useMinWidthForRightSection();

  return {
    isBookingsFormOpen,
    minWidthForRightSection,
    setIsBookingsFormOpen,
    setMinWidthForRightSection,
  };
};

interface BookingsFormViewSlice {
  isBookingsFormOpen: boolean;
  minWidthForRightSection: number | null;
  setIsBookingsFormOpen: (isOpen: boolean) => void;
  setMinWidthForRightSection: (width: number) => void;
}
// add other interfaces here
interface DocumentViewStoreProps extends BookingsFormViewSlice {}
