import { AvatarStack, Item, SelectField } from '@candisio/design-system';
import { AvatarWithStatusContainer } from 'containers/absence/AvatarWithStatusContainer';
import { ActivityUser } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

interface RoleAccessCardProps {
  users?: ActivityUser[] | null;
  roleTranslationKey: string;
  accessTranslationKey: string;
}

export const DocumentAccessUsersRow = ({
  users,
  roleTranslationKey,
  accessTranslationKey,
}: RoleAccessCardProps) => {
  const [t] = useTranslation();

  if (!users?.length) {
    return null;
  }

  return (
    <div className="grid grid-cols-[70fr_30fr] gap-4">
      <div className="grid grid-flow-col items-center grid-cols-[auto_1fr] gap-2">
        <AvatarStack limit={3} size="small" tooltipMaxHeight="400px">
          {users.map(user => (
            <AvatarWithStatusContainer
              key={user.id}
              name={user.name}
              userId={user.id}
              img={user.avatarUrl ?? undefined}
            />
          ))}
        </AvatarStack>
        <span className="text-basic">{roleTranslationKey}</span>
      </div>
      <div className="min-w-max">
        <SelectField
          readOnly
          select={{
            selectedKey: t(accessTranslationKey),
            children(item) {
              return <Item>{item.name}</Item>;
            },
            items: [
              {
                id: t(accessTranslationKey),
                name: t(accessTranslationKey),
              },
            ],
          }}
        />
      </div>
    </div>
  );
};
