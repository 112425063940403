import { Button, Heading } from '@candisio/design-system';
import { useDocumentViewStore } from 'components/Layouts/DetailsLayoutContext';
import { useTranslation } from 'react-i18next';
import { useProcessingFormOverlayContext } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';

type BookingsFormContainerLayoutProps = {
  children: React.ReactNode;
};

export const BookingsFormContainerLayout = ({
  children,
}: BookingsFormContainerLayoutProps) => {
  const [t] = useTranslation('split-bookings');
  const { onClose, headingId } = useProcessingFormOverlayContext();
  const { setIsBookingsFormOpen } = useDocumentViewStore();

  return (
    <div className="flex flex-col gap-4 bg-gray-200 h-full p-4 pt-6">
      <div className="flex justify-between">
        <Heading as="h2" id={headingId} left="0">
          {t('title')}
        </Heading>
        <Button
          right="0"
          icon="close"
          label={t('common.close')}
          onClick={() => {
            onClose?.();
            setIsBookingsFormOpen(false);
          }}
          variant="tertiary"
        />
      </div>
      {children}
    </div>
  );
};
