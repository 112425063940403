import { Flex, Switch, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUpdateCurrentUserLocale } from 'hooks/useCurrentUserLocale';

export const LanguageToggle = () => {
  const { locale, isPending, updateLocale } = useUpdateCurrentUserLocale();

  return (
    <Flex
      id="language-toggle"
      direction="column"
      alignItems="center"
      borderRadius="large"
    >
      <Flex alignItems="center" gap="space8">
        <Text
          fontSize="basic"
          fontWeight={locale === Locale.De ? 'bold' : 'normal'}
        >
          DE
        </Text>
        <Switch
          label=""
          disabled={isPending}
          checked={locale === Locale.En}
          onChange={isSelected => {
            const nextLocale = isSelected ? Locale.En : Locale.De;
            updateLocale(nextLocale);
          }}
        />
        <Text
          fontSize="basic"
          fontWeight={locale === Locale.En ? 'bold' : 'normal'}
        >
          EN
        </Text>
      </Flex>
    </Flex>
  );
};
