import { useState, useEffect, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';

const WIDTH_CHANGE_THRESHOLD = 20;

export function useIsWindowResizing(debounceTime = 200): boolean {
  const [isResizing, setIsResizing] = useState(false);
  const prevWidth = useRef(window.innerWidth);
  const initialResizedSkip = useRef(false);
  const resizeTimeout = useRef<NodeJS.Timeout>();

  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (!initialResizedSkip.current) {
          initialResizedSkip.current = true;
          prevWidth.current = window.innerWidth;
          return;
        }

        const currentWidth = window.innerWidth;
        const widthDiff = Math.abs(currentWidth - prevWidth.current);

        if (widthDiff > WIDTH_CHANGE_THRESHOLD) {
          setIsResizing(true);
          if (resizeTimeout.current) {
            clearTimeout(resizeTimeout.current);
          }
          resizeTimeout.current = setTimeout(() => {
            setIsResizing(false);
          }, 100);
        }

        prevWidth.current = currentWidth;
      }, debounceTime),
    [debounceTime]
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return isResizing;
}
