import {
  ReactNode,
  createContext,
  createElement,
  useContext,
  useRef,
} from 'react';

export type StoreProviderAndHook<T, R> = [
  React.FC<{ children: ReactNode; initialValue: T }>,
  () => R,
];

export const createStoreProvider = <T, R>(
  storeCreator: (initialValue: T) => R,
  providerName = 'StoreProvider',
  hookName = 'useStoreContext'
): StoreProviderAndHook<T, R> => {
  const StoreContext = createContext<null | R>(null);

  const useStoreContext = () => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error(`You must use ${hookName}() inside a <${providerName}>`);
    }
    return store;
  };

  const StoreProvider: React.FC<{ children: ReactNode; initialValue: T }> = ({
    children,
    initialValue,
  }) => {
    const store = useRef(storeCreator(initialValue)).current;
    return createElement(StoreContext.Provider, { value: store }, children);
  };

  return [StoreProvider, useStoreContext];
};
