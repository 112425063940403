import {
  Button,
  Grid,
  Link,
  MenuItem,
  Text,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import { useComparisonView } from 'components/DocumentViewer/useComparisonView';
import { isVisibleInWallet } from 'components/Transactions/TransactionDetailsCard/CreditCardLinksTooltip/CreditCardLinksTooltip';
import { useTransactionsLinks } from 'components/Transactions/TransactionDetailsCard/CreditCardLinksTooltip/useTransactionsLinks';
import { UnlinkActionConfirm as TransactionUnlinkActionConfirm } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCardContextMenu';
import {
  PopoverDescription,
  popoverDescription,
} from 'components/Transactions/TransactionDetailsCard/utils';
import { useDocumentRelationshipsActions } from 'containers/document-relationships/useDocumentRelationshipsActions';
import {
  DocumentStatus,
  EcmDocumentStatus,
  useGetCardStatusByIdQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { TrackingEvents } from 'providers/AnalyticsProvider/events';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import {
  ComponentProps,
  ElementRef,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DRAWER, Drawers } from 'views/DocumentDetails/useDrawerState';
import { useGetOpenDocument } from 'views/Inbox/hooks/useDocumentNavigation';
import { useUnlinkTransaction } from 'views/Inbox/hooks/useUnlinkTransaction';
import { ActionConfirm } from './ActionConfirm';
import { ActionsMenu } from './ActionsMenu';
import {
  DocumentSummaryCard,
  DocumentSummaryCardImproved,
} from './DocumentSummaryCard';
import { Transaction, TransactionSummaryCard } from './TransactionSummaryCard';
import { RelatedDocument } from './hooks/useDocumentRelations';

type DocumentRelationItemProps = RelatedDocument & {
  documentName: string;
  isUnlinkingDocumentsPending: boolean;
  handleUnlinkDocuments: ReturnType<
    typeof useDocumentRelationshipsActions
  >['handleUnlinkDocuments'];
  readOnly?: boolean;
  selectedRelationId?: string;
  setSelectedRelationId?: (id: string) => void;
  mainDocumentId: string;
};

export const DocumentRelationItem = forwardRef<
  ForwardedRef<typeof DocumentSummaryCard>,
  DocumentRelationItemProps
>(
  (
    {
      handleUnlinkDocuments,
      isUnlinkingDocumentsPending: isUnlinkPending = false,
      readOnly,
      selectedRelationId,
      setSelectedRelationId,
      mainDocumentId,
      ...relatedDocument
    },
    ref
  ) => {
    const [t] = useTranslation();
    const { track } = useAnalytics();
    const { toggleSidebar, isNavigationOpen } = useNavigationSidebarContext();

    const { id, relationId, type, documentName, globalDocumentId } =
      relatedDocument;
    const unlinkDocument = useCallback(() => {
      if (!relationId) return;

      void handleUnlinkDocuments({
        documentName: documentName || id,
        relationId,
      });
    }, [handleUnlinkDocuments, documentName, id, relationId]);

    const { getOpenDocumentLink } = useGetOpenDocument();

    const openDocumentAction = useCallback(() => {
      track(TrackingEvents.DOCUMENT_RELATION_OPENED, {
        global_document_id: mainDocumentId,
        relation_global_document_id: globalDocumentId ?? '',
        compare_view_open: isComparisonView,
      });
      const documentHref = getOpenDocumentLink(id, type, false, {
        [DRAWER]: Drawers.RELATIONSHIPS,
      });
      window.open(documentHref, '_blank');
    }, [
      getOpenDocumentLink,
      id,
      type,
      track,
      mainDocumentId,
      globalDocumentId,
    ]);

    const { isComparisonView, setComparisonMode } = useComparisonView();

    const openCompareDocumentAction = useCallback(() => {
      track(TrackingEvents.DOCUMENT_COMPARED, {
        global_document_id: mainDocumentId,
        compared_global_document_id: globalDocumentId ?? '',
        compare_view_open: isComparisonView,
      });

      if (isComparisonView && relatedDocument.documentFileId)
        return setSelectedRelationId?.(relatedDocument.documentFileId);

      if (relatedDocument.documentFileId)
        setSelectedRelationId?.(relatedDocument.documentFileId);
      setComparisonMode();
      if (isNavigationOpen) toggleSidebar();
    }, [
      isComparisonView,
      setComparisonMode,
      relatedDocument.documentFileId,
      setSelectedRelationId,
      isNavigationOpen,
      toggleSidebar,
      track,
      mainDocumentId,
      globalDocumentId,
    ]);

    const {
      open: openPopover,
      close: closePopover,
      popoverProps,
      popoverRef,
      triggerRef,
    } = usePopover({ placement: 'right top' });

    const summaryCard = (
      <DocumentSummaryCardImproved
        ref={ref}
        onAction={openCompareDocumentAction}
        onPreviewClick={openDocumentAction}
        isLoading={isUnlinkPending}
        document={relatedDocument}
        selectedRelationId={selectedRelationId}
        action={
          <Button
            size="xsmall"
            variant="tertiary"
            icon="unlinkOutlined"
            iconSize="space16"
            onClick={openPopover}
            label={t('document.tabs.relationships.removeDocument')}
            disabled={readOnly}
            ref={triggerRef}
          />
        }
      />
    );

    return (
      <>
        {summaryCard}
        <UnlinkActionConfirm
          ref={popoverRef}
          popoverProps={popoverProps}
          isActionPending={isUnlinkPending}
          closePopover={closePopover}
          handleConfirm={unlinkDocument}
        />
      </>
    );
  }
);

DocumentRelationItem.displayName = 'DocumentRelationItem';

const UnlinkActionConfirm = forwardRef<
  ElementRef<typeof ActionConfirm>,
  Omit<ComponentProps<typeof ActionConfirm>, 'informationText'>
>(({ popoverProps, isActionPending, closePopover, handleConfirm }, ref) => {
  const [t] = useTranslation();

  const informationText = (
    <Grid gap="space16">
      <Text fontWeight="semibold">
        {t('document.tabs.relationships.removeConfirmation.title')}
      </Text>
      <Text>{t('document.tabs.relationships.removeConfirmation.text')}</Text>
    </Grid>
  );

  const cancelLabel = t(
    'document.tabs.relationships.removeConfirmation.cancelLabel'
  );

  const confirmLabel = (
    <TruncatedText>
      {t('document.tabs.relationships.removeConfirmation.confirmLabel')}
    </TruncatedText>
  );

  return (
    <ActionConfirm
      ref={ref}
      popoverProps={popoverProps}
      isActionPending={isActionPending}
      closePopover={closePopover}
      handleConfirm={handleConfirm}
      informationText={informationText}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      maxWidth="450px"
    />
  );
});

UnlinkActionConfirm.displayName = 'UnlinkActionConfirm';

const unlinkableStatuses: EcmDocumentStatus[] = [
  EcmDocumentStatus.New,
  EcmDocumentStatus.Open,
  EcmDocumentStatus.Approved,
  EcmDocumentStatus.Rejected,
] as const;

export const isDocumentUnlinkable = (
  documentStatus: DocumentStatus | EcmDocumentStatus
) =>
  documentStatus ? unlinkableStatuses.includes(documentStatus as any) : false;

const openNewTab = (to: { pathname: string; search: string }) =>
  window.open(`${to.pathname}?${to.search}`, '_blank');

type TransactionRelatedItemProps = {
  transaction: ComponentProps<typeof TransactionSummaryCard>['transaction'];
  documentId?: string;
  documentStatus?: EcmDocumentStatus | DocumentStatus;
};

export const TransactionRelatedItem = forwardRef<
  ElementRef<typeof TransactionSummaryCard>,
  TransactionRelatedItemProps
>(({ transaction, documentId, documentStatus }, ref) => {
  const [t] = useTranslation();

  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const { isUnlinkPending, unlinkTransaction } = useUnlinkTransaction(
    transaction.id,
    documentId
  );

  const canUnlinkTransaction =
    !!documentStatus && isDocumentUnlinkable(documentStatus);

  const menuItems: MenuItem[] = useTransactionMenuItems(
    transaction,
    canUnlinkTransaction,
    openPopover
  );

  const actionsMenuLabel = t('documentContextMenu.openMenu');

  const fallbackDescription =
    documentStatus &&
    popoverDescription[documentStatus as keyof PopoverDescription];

  return (
    <>
      <TransactionSummaryCard
        ref={ref}
        transaction={transaction}
        menu={
          <ActionsMenu
            label={actionsMenuLabel}
            isActionPending={isUnlinkPending}
            items={menuItems}
            ref={triggerRef}
          />
        }
      />
      <TransactionUnlinkActionConfirm
        ref={popoverRef}
        popoverProps={popoverProps}
        isActionPending={isUnlinkPending}
        closePopover={closePopover}
        handleConfirm={unlinkTransaction}
        fallbackDescription={fallbackDescription}
      />
    </>
  );
});

TransactionRelatedItem.displayName = 'TransactionRelatedItem';

const renderLink = (item: MenuItem) => (
  <Link external color="gray">
    {item.label}
  </Link>
);

export const useTransactionMenuItems = (
  transaction: Transaction | undefined,
  canUnlinkTransaction: boolean,
  openPopover: () => void
): MenuItem[] => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const links = useTransactionsLinks(transaction?.cardId);

  const user = useCurrentUser();
  const { isCardManager } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const { data } = useGetCardStatusByIdQuery({
    variables: { id: transaction?.cardId ?? '' },
    skip: !transaction?.cardId,
  });

  const cardStatus = data?.getCardIssuerCardById.status;
  const membershipId = transaction?.member?.membershipId ?? undefined;

  const isTransactionOwner = membershipId === user?.id;
  const isCardVisibleInWallet = !!cardStatus && isVisibleInWallet(cardStatus);
  const isAllowedToSeeCardSettings =
    showAllExtraFeatures &&
    ((isTransactionOwner && isCardVisibleInWallet) || isCardManager);

  interface MenuItemProps extends MenuItem {
    showMenuItem?: boolean;
  }

  const menuItems: MenuItemProps[] = useMemo(
    () =>
      [
        {
          id: '1',
          label: t(
            'transactionDetailsCard.creditCardLinks.archiveTransactions'
          ),
          onAction: () => {
            if (!links) return;
            openNewTab(links.archiveTransactions);
          },
          renderItem: renderLink,
        },
        {
          id: '2',
          label: t('transactionDetailsCard.creditCardLinks.cardSettings'),
          onAction: () => {
            if (!links) return;

            const to = isCardManager
              ? links.cardManagerView
              : links.dashboardWallet;

            openNewTab(to);
          },
          renderItem: renderLink,
          showMenuItem: isAllowedToSeeCardSettings,
        },
        {
          id: '3',
          label: t('common:document.tabs.relationships.removeTransaction'),
          onAction: openPopover,
          showMenuItem: canUnlinkTransaction,
        },
      ].filter(item => item.showMenuItem !== false),
    [
      canUnlinkTransaction,
      isAllowedToSeeCardSettings,
      isCardManager,
      links,
      openPopover,
      t,
    ]
  );

  return menuItems;
};
