import { Flex, Grid } from '@candisio/design-system';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import styles from './NavigationSidebar.module.css';
import { NavigationSidebarDropZone } from './NavigationSidebarDropZone';
import { NavigationSidebarToggler } from './NavigationSidebarToggler';
import { OrganizationSwitcher } from './OrganizationSwitcher/OrganizationSwitcher';
import { MenuDashboard } from './components/MenuItems/MenuDashboard/MenuDashboard';
import { MenuDocumentUpload } from './components/MenuItems/MenuDocumentUpload/MenuDocumentUpload';
import { MenuDocumentUploadAccessWrapper } from './components/MenuItems/MenuDocumentUpload/MenuDocumentUploadAccessWrapper';
import { MenuIntercom } from './components/MenuItems/MenuIntercom/MenuIntercom';
import { MenuNotifications } from './components/MenuItems/MenuNotifications/MenuNotifications';
import { MenuSettings } from './components/MenuItems/MenuSettings/MenuSettings';
import { MenuUploadDocument as MenuDocumentUploadDeprecated } from './components/MenuItems/MenuUploadDocument/MenuUploadDocument';
import { UserProfile } from './components/MenuItems/UserProfile/UserProfile';
import { OverflowNavigationIcons } from './components/OverflowNavigationIcons';
import { useIconOverflow } from './hooks/useIconOverflow';
import { useNavigationIcons } from './hooks/useNavigationIcons';

export const NavigationSidebar = () => {
  const { isNavigationOpen } = useNavigationSidebarContext();
  const documentUploadReworkFF = useCandisFeatureFlags(
    FEATURE_FLAGS.documentUploadRework
  );

  const { initialOverflowIcons } = useNavigationIcons();

  const {
    visibleIcons,
    hiddenIcons,
    navigationSidebarRef,
    overflowRef,
    headerRef,
    bottomRef,
    itemRef,
  } = useIconOverflow(initialOverflowIcons);

  return (
    <Flex
      className={styles.sidebar}
      id="menu"
      data-open={isNavigationOpen}
      role="menu"
      justifyContent="space-between"
      as="aside"
      padding="space12 space16 space24 space8"
      position="relative"
      height="100vh"
    >
      <NavigationSidebarDropZone />
      <NavigationSidebarToggler />
      <Flex
        as="nav"
        gap="space20"
        direction="column"
        justifyContent="space-between"
        height="100%"
        width="100%"
        ref={navigationSidebarRef}
      >
        <Grid gap="space8">
          <Grid ref={headerRef} gap="space24">
            <OrganizationSwitcher isNavigationOpen={isNavigationOpen} />
            <Grid paddingLeft="space8">
              <MenuDashboard expanded={isNavigationOpen} />
            </Grid>
          </Grid>

          <Grid paddingLeft="space8" gap="space8" ref={overflowRef}>
            <OverflowNavigationIcons
              icons={visibleIcons}
              isNavigationOpen={isNavigationOpen}
              hiddenIcons={hiddenIcons}
              itemRef={itemRef}
            />
          </Grid>
        </Grid>

        <Grid paddingLeft="space8" gap="space8" ref={bottomRef}>
          {documentUploadReworkFF ? (
            <MenuDocumentUploadAccessWrapper>
              <MenuDocumentUpload expanded={isNavigationOpen} />
            </MenuDocumentUploadAccessWrapper>
          ) : (
            <MenuDocumentUploadDeprecated expanded={isNavigationOpen} />
          )}

          <MenuIntercom expanded={isNavigationOpen} />
          <MenuNotifications expanded={isNavigationOpen} />
          <MenuSettings expanded={isNavigationOpen} />
          <UserProfile expanded={isNavigationOpen} />
        </Grid>
      </Flex>
    </Flex>
  );
};
