import { PerDiemMealsButtonGroup } from './PerDiemMealsButtonGroup/PerDiemMealsButtonGroup';
import styles from './DaysStep.module.css';
import { perDiemMealbuttons } from '../PerDiemForm';
import { generateDaysFieldNames } from '../utils/generatePerDiemFieldNames';

interface DayProps {
  formIndex: number;
  dayIndex: number;
  date: string;
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
}

export const Day = ({
  date,
  dayIndex,
  formIndex,
  onUpdatePerDiemDays,
}: DayProps) => {
  const fields = generateDaysFieldNames(formIndex, dayIndex);
  return (
    <li className={styles['day-item']} data-testid={`per-diem-day-${dayIndex}`}>
      <span className="text-sm text-gray-800 font-medium">{date}</span>
      <PerDiemMealsButtonGroup
        name={fields.meals}
        buttons={perDiemMealbuttons}
        onChange={() => onUpdatePerDiemDays(formIndex)()}
      />
    </li>
  );
};
