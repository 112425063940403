import { Button, Tooltip, useTooltip } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useNavigationSidebarContext } from 'providers/NavigationSidebarProvider/NavigationSidebarProvider';
import { useTranslation } from 'react-i18next';
import styles from './NavigationSidebar.module.css';

export const NavigationSidebarToggler = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { isNavigationOpen, toggleSidebar } = useNavigationSidebarContext();

  const {
    isOpen: isTooltipOpen,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
  } = useTooltip({
    placement: 'right',
    passiveTrigger: true,
    delay: 2000,
  });

  const navigationLabel = isNavigationOpen
    ? t('toggleLabelClosed')
    : t('toggleLabelOpen');

  return (
    <>
      <Button
        id="menuButton"
        className={styles.menuButton}
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={navigationLabel}
        aria-pressed={isNavigationOpen}
        size="small"
        icon={isNavigationOpen ? 'menuExpanded' : 'menuCollapsed'}
        variant="tertiary"
        onClick={() => toggleSidebar()}
        position="absolute"
        right={isNavigationOpen ? '8px' : '-20px'}
        top="29px"
        zIndex={1}
        ref={triggerRef}
        {...triggerProps}
      />
      {isTooltipOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {navigationLabel}
        </Tooltip>
      )}
    </>
  );
};
