import {
  Box,
  Button,
  Grid,
  Icon,
  Modal,
  Paragraph,
  ScrollBox,
  Skeleton,
  Tag,
  TruncatedText,
  usePopover,
} from '@candisio/design-system';
import clsx from 'clsx';
import { AttachmentsTableContainer } from 'components/DocumentViewer/AttachmentsTable/AttachmentsTable';
import {
  DocumentViewSwitcherProps,
  VIEW_SWITCH_DELAY,
} from 'components/DocumentViewer/DocumentViewSwitcher';
import { PdfThumbnailViewer } from 'components/DocumentViewer/PdfViewer/PdfThumbnailViewer';
import { PDFDetails } from 'components/DocumentViewer/utils';
import { Filename } from 'components/Filename/Filename';
import { UploadDropZone } from 'components/UploadDropZone/UploadDropZone';
import { Attachment } from 'hooks/useAttachments/useAttachments';
import { CanAddAttachments } from 'hooks/useAttachments/useCanAddAttachments';
import {
  ComponentProps,
  HTMLProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDisabledAttachmentButtonLabelText } from 'views/Inbox/DocumentProcessing/util/useDisabledAttachmentButtonLabelText';
import { ActionConfirm } from './ActionConfirm';
import styles from './AttachmentsSegment.module.css';
import { SidebarSegmentPromo } from './SidebarSegmentPromo';
import { useDeleteAction } from './hooks/useDeleteAction';
import { useDetachAction } from './hooks/useDetachAction';
import { useAttachmentsSidebarPromo } from './sidebarPromoHooks';
import { useFilesSelected } from './useFilesSelected';

type AttachmentsSegmentProps = {
  attachments: Attachment[];
  selectedPdf: PDFDetails;
  documentFile: PDFDetails;
  documentId: string;
  attachPermissions: CanAddAttachments;
  onAttachmentClick: (attachment: AttachmentFile) => void;
  view?: DocumentViewSwitcherProps['view'];
  setView?: DocumentViewSwitcherProps['setView'];
};

export const AttachmentsSegment = ({
  attachments,
  selectedPdf,
  documentId,
  documentFile,
  attachPermissions,
  onAttachmentClick,
  view,
  setView,
}: AttachmentsSegmentProps) => {
  const [t] = useTranslation();
  const handleAttachmentClick = (attachment: AttachmentFile) => {
    if (view === 'xml' && onAttachmentClick) {
      setView?.('pdf');
      setTimeout(() => {
        onAttachmentClick(attachment);
      }, VIEW_SWITCH_DELAY);
    } else {
      onAttachmentClick(attachment);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const { isSeen, markAsSeen } = useAttachmentsSidebarPromo();

  const { onAttachFiles, isAttachingFiles } = useFilesSelected(
    attachments,
    documentFile,
    documentId
  );

  const canAttach = attachPermissions.fromDisk || attachPermissions.fromCandis;

  const canOnlyAttachFromDisk =
    attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const isAttachActionDisabled =
    !attachPermissions.fromDisk && !attachPermissions.fromCandis;

  const disabledLabel = useDisabledAttachmentButtonLabelText(canAttach);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onUploadFileClick = () => {
    fileUploadRef.current?.click();
  };

  const handleFileUpload = () => {
    closeModal();
    onUploadFileClick();
  };

  const onFilesChanged = () => {
    if (!fileUploadRef.current?.files) return;

    onAttachFiles(Array.from(fileUploadRef.current.files));
    fileUploadRef.current.value = '';
  };

  const canAttachFromCandis = !canOnlyAttachFromDisk;

  const isEmpty = attachments.length === 0;

  const documentFileAsAttachment: AttachmentFile = useMemo(
    () => ({
      id: documentFile?.id ?? '',
      isDetachable: false,
      isRemovable: false,
      name: t('document.tabs.attachments.mainDocument'),
      originalDocumentId: documentFile?.id ?? '',
      url: documentFile?.url ?? '',
    }),
    [documentFile, t]
  );

  return (
    <ScrollBox
      className="rounded-medium !mx-2"
      scrollDirection="y"
      scrollbarGutter="stable"
    >
      <UploadDropZone
        onDrop={files => {
          onAttachFiles(Array.from(files));
        }}
      >
        <div className="flex flex-col items-center gap-2">
          <Icon icon="menuUpload" size={28} />
          <span className="text-large">
            <Trans
              i18nKey="document.tabs.attachments.dropzone"
              components={{ strong: <strong /> }}
            />
          </span>
        </div>
      </UploadDropZone>
      <Grid
        as="ul"
        rowGap="space8"
        listStyle="none"
        alignContent="start"
        justifyItems="stretch"
        borderRadius="medium"
        height="100%"
        padding="0"
        {...(isEmpty && {
          padding: 'space12',
          background: 'gray50',
        })}
      >
        {!isSeen && !isEmpty && (
          <SidebarSegmentPromo
            markAsSeen={markAsSeen}
            i18nKey="ecm:promo.documentRelationsImproved.attachments.segment"
          />
        )}
        {isEmpty && <AttachmentsEmptyState />}
        {!isEmpty && (
          <AttachmentItem
            key={documentFile.id}
            isActive={selectedPdf.id === documentFile.id}
            attachment={documentFileAsAttachment}
            onAction={handleAttachmentClick}
          />
        )}
        {attachments.map(attachment => (
          <AttachmentItem
            key={attachment.id}
            isActive={selectedPdf.id === attachment.id}
            attachment={attachment}
            onAction={handleAttachmentClick}
          />
        ))}
        <Grid
          position="sticky"
          bottom="0"
          paddingTop="space16"
          justifyItems="stretch"
          gap="space8"
          background={
            isEmpty
              ? undefined
              : 'linear-gradient(to top, gray200 80%, transparent 95% 100%)'
          }
        >
          <Button
            onClick={onUploadFileClick}
            loading={isAttachingFiles}
            disabled={isAttachActionDisabled}
            label={disabledLabel}
            variant="primary"
            color="blue"
            icon="plus"
          >
            <TruncatedText>
              {t('document.attachments.attachPdfFromDisk')}
            </TruncatedText>
          </Button>
          {canAttachFromCandis && (
            <Button
              onClick={openModal}
              loading={isAttachingFiles}
              disabled={isAttachActionDisabled}
              label={disabledLabel}
              variant="tertiary"
              color="gray"
              icon="plus"
            >
              <TruncatedText>
                {t('document.attachments.attachPdfFromCandis')}
              </TruncatedText>
            </Button>
          )}
        </Grid>
      </Grid>

      <input
        type="file"
        accept="application/pdf"
        ref={fileUploadRef}
        onChange={onFilesChanged}
        disabled={!canAttach}
        multiple
        hidden
      />

      <Modal
        background="gray200"
        closeLabel={t('common.close')}
        width="80vw"
        isOpen={isModalOpen}
        onClose={closeModal}
        padding="0"
        scrollDirection="none"
        title={t('document.attachments.attachPdfFromCandis')}
      >
        <AttachmentsTableContainer
          documentId={documentId}
          onDocumentsAttached={closeModal}
          onUploadFileClick={handleFileUpload}
        />
      </Modal>
    </ScrollBox>
  );
};

const AttachmentsEmptyState = () => {
  const [t] = useTranslation();
  return (
    <>
      <Grid gap="space16" paddingBottom="space16" justifySelf="start">
        <Tag variant="callout">{t('tag.new')}</Tag>
        <Paragraph fontSize="large" width="11rem">
          {t('document.attachments.newViewInfo')}
        </Paragraph>
      </Grid>
      <LoadingAttachment />
      <LoadingAttachment />
    </>
  );
};
const LoadingAttachment = () => (
  <AttachmentItemWrapper background="gray200" maxHeight="76px" height="76px">
    <AttachmentItemLayout className={styles['attachment-layout--loading']}>
      <div className={styles['attachment-icon-wrapper']}>
        <Icon
          icon="attachment"
          size="space20"
          color="gray600"
          minWidth="space20"
        />
      </div>
      <div className="grid gap-2">
        <Skeleton height="space8" width="space96" />
        <Skeleton height="space8" width="space64" />
      </div>
    </AttachmentItemLayout>
  </AttachmentItemWrapper>
);

const translationMap = {
  delete: 'document.compact.history.documentAttachment.remove.warning',
  detach: 'document.compact.history.documentAttachment.detach.warning',
  '': '',
} as const;

type allowedState = 'delete' | 'detach' | '';

const AttachmentItemWrapper = ({
  children,
  isActive,
  onPointerDown,
  background = 'gray250',
  ...rest
}: ComponentProps<typeof Box>) => (
  <Box
    as="li"
    role="row"
    width="100%"
    paddingY="space5"
    paddingLeft="space5"
    paddingRight="space24"
    background={isActive ? 'gray300' : background}
    borderRadius="medium"
    {...(onPointerDown && {
      onPointerDown,
      cursor: 'pointer',
      hover: { background: 'gray300' },
    })}
    {...rest}
  >
    {children}
  </Box>
);

const AttachmentItemLayout = ({
  children,
  className,
  ...rest
}: HTMLProps<HTMLDivElement>) => (
  <div
    className={clsx(styles['attachment-layout'], className)}
    role="gridcell"
    {...rest}
  >
    {children}
  </div>
);

const AttachmentItem = ({
  isActive = false,
  attachment,
  onAction,
}: ComponentProps<typeof AttachmentItemWrapper> & {
  isActive?: boolean;
  attachment: AttachmentFile;
  onAction: (attachment: AttachmentFile) => void;
}) => {
  const [t] = useTranslation();

  const [isDetachPending, setIsDetachPending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const [openedConfirm, setOpenedConfirm] = useState<allowedState>('');

  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const handleDetach = useDetachAction(attachment, setIsDetachPending);
  const handleDelete = useDeleteAction(attachment, setIsDeletePending);

  const actionsMap = useMemo(
    () => ({
      delete: handleDelete,
      detach: handleDetach,
      '': Promise.resolve,
    }),
    [handleDelete, handleDetach]
  );

  const handleClick = () => onAction(attachment);

  const warningText = t(translationMap[openedConfirm]);

  const isActionPending = isDetachPending || isDeletePending;

  return (
    <>
      <AttachmentItemWrapper
        onPointerDown={handleClick}
        isActive={isActive}
        aria-describedby={attachment.id + '-item'}
      >
        <AttachmentItemLayout>
          <div className={styles['attachment-preview']}>
            <PdfThumbnailViewer documentFile={attachment} />
          </div>
          <Filename
            fontSize="small"
            color="gray800"
            fontWeight="semibold"
            id={attachment.id + '-item'}
            lineClamp={2}
          >
            {attachment.name}
          </Filename>
          {(attachment.isRemovable || attachment.isDetachable) && (
            <div className={styles['attachment-actions']} ref={triggerRef}>
              {attachment.isDetachable && (
                <Button
                  icon="unlink"
                  label={t('document.tabs.attachments.detachAttachment')}
                  onClick={() => {
                    setOpenedConfirm('detach');
                    openPopover();
                  }}
                  variant="tertiary"
                />
              )}
              {attachment.isRemovable && (
                <Button
                  icon="trash"
                  label={t('document.tabs.attachments.deleteAttachment')}
                  onClick={() => {
                    setOpenedConfirm('delete');
                    openPopover();
                  }}
                  variant="tertiary"
                />
              )}
            </div>
          )}
        </AttachmentItemLayout>
      </AttachmentItemWrapper>
      <ActionConfirm
        ref={popoverRef}
        informationText={warningText}
        popoverProps={popoverProps}
        isActionPending={isActionPending}
        closePopover={closePopover}
        handleConfirm={actionsMap[openedConfirm]}
      />
    </>
  );
};

type AttachmentFile = Pick<
  Attachment,
  'originalDocumentId' | 'id' | 'name' | 'isRemovable' | 'isDetachable' | 'url'
>;
