import { useTheme } from '@candisio/design-system';
import { TruncatedText } from '@candisio/design-system/next';
import { CellContext } from '@tanstack/react-table';
import {
  MealRate,
  PerDiemDayCalculationDataProps,
} from 'views/Reimbursement/hooks/usePerDiemItemCalculation';

export const MealCell = ({
  getValue,
}: CellContext<PerDiemDayCalculationDataProps, MealRate | undefined>) => {
  const { fontSizes } = useTheme();
  const value = getValue();

  if (!value) return <span style={{ fontSize: fontSizes.basic }}>-</span>;

  return (
    <div
      className="flex flex-col text-red-500"
      style={{ fontSize: fontSizes.basic }}
    >
      <TruncatedText>{value.rateDifference}</TruncatedText>
      <span className="text-gray-500" style={{ fontSize: fontSizes.small }}>
        {value.percentageDifference}
      </span>
    </div>
  );
};
