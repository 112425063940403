import { Icon, Tooltip, useTooltip } from '@candisio/design-system';
import { useTranslation } from 'react-i18next';
import styles from './power-search-input.module.css';
import { PowerSearchActionProps, PowerSearchInputProps } from '../types';
import { PowerSearchField } from './PowerSearchField';

export const PowerSearchInput = (props: PowerSearchInputProps) => {
  const {
    clearTooltip,
    inputMaxLength,
    placeholder,
    value,
    readOnly = false,
    onValueChange,
  } = props;

  const [t] = useTranslation();

  return (
    <PowerSearchField
      inputProps={{
        'aria-label': t('search.label'),
        className: styles['power-search-input'],
        placeholder,
        readOnly,
        maxLength: inputMaxLength,
        value,
        onChange: e => onValueChange(e.target.value),
      }}
    >
      <PowerSearchAction
        value={value}
        onClear={() => onValueChange('')}
        clearTooltip={clearTooltip}
      />
    </PowerSearchField>
  );
};

const PowerSearchAction = ({
  value,
  onClear,
  clearTooltip,
}: PowerSearchActionProps) => {
  const isClear = value.length > 0;
  const icon = isClear ? 'close' : 'search';
  const handleOnClick = () => {
    if (!isClear) {
      return;
    }
    onClear();
  };

  const [t] = useTranslation();
  const actionLabel = isClear ? clearTooltip : t('search.label');

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip();
  return (
    <>
      <button
        ref={triggerRef}
        {...triggerProps}
        type="button"
        aria-label={actionLabel}
        className={styles['power-search-input-action']}
        onClick={handleOnClick}
        tabIndex={isClear ? 0 : -1}
      >
        <Icon key={icon} icon={icon} size="space16" />
      </button>
      {clearTooltip && isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {clearTooltip}
        </Tooltip>
      )}
    </>
  );
};
