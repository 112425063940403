import clsx from 'clsx';

interface DashedBorderProps {
  className?: string;
}

export const DashedBorder = ({ className }: DashedBorderProps) => {
  return (
    <svg className={clsx(className)}>
      <rect
        width="100%"
        height="100%"
        fill="none"
        rx="8"
        ry="8"
        stroke="currentColor"
        stroke-width="2"
        stroke-dasharray="6,6"
      />
    </svg>
  );
};
