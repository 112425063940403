import styles from '../DaysStep.module.css';
import { Day } from '../Day';
import { ReimbursementItemFormPerDiemDay } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { Button } from '@candisio/design-system';

interface CollapsibleDaysListProps {
  formIndex: number;
  collapsibleDays: (ReimbursementItemFormPerDiemDay & { id: string })[];
  onUpdatePerDiemDays: (index: number) => () => Promise<void>;
  onSetIsMiddleDayHidden: (value: boolean) => void;
}

export const CollapsibleDaysList = ({
  formIndex,
  collapsibleDays,
  onUpdatePerDiemDays,
  onSetIsMiddleDayHidden,
}: CollapsibleDaysListProps) => {
  return (
    <li>
      <ol className={styles['grouped-day-list']}>
        <li
          className={styles['day-item']}
          onClick={e => {
            e.stopPropagation();
            onSetIsMiddleDayHidden(true);
          }}
        >
          <Button icon="caretDown" variant="tertiary" justifySelf="start" />
        </li>
        {collapsibleDays.map((day, index) => {
          return (
            <Day
              key={day.id}
              date={day.date}
              formIndex={formIndex}
              dayIndex={index + 1}
              onUpdatePerDiemDays={onUpdatePerDiemDays}
            />
          );
        })}
        <li
          className={styles['day-item']}
          onClick={e => {
            e.stopPropagation();
            onSetIsMiddleDayHidden(true);
          }}
        >
          <Button icon="caretUp" variant="tertiary" justifySelf="start" />
        </li>
      </ol>
    </li>
  );
};
