import { Button, Paragraph, SectionHeading } from '@candisio/design-system';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { CONTRACT_UTM_SOURCE } from 'components/ProductPromotions/constants/utmSource';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { DocumentType } from 'generated-types/graphql.types';
import { isEmpty } from 'lodash';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDocumentTypeContext } from 'views/DocumentDetails/components/DocumentTypeContext';
import { FormAction } from '../FormAction';
import { StorageFormValues } from './storageFormSchema';

export interface StorageFormActionsProps {
  /** Determines if document should be stored as a sensitive contract */
  isNewSensitiveContract?: boolean;
  /** Called when document should be deleted */
  onDeleteDocument?: () => Promise<void>;
  /** Determines if document status is new */
  isNewDocument?: boolean;
  /** Whether the user can remove the document */
  canRemoveDocument?: boolean;
}

/**
 * Actions for ECM storage form:
 * - Store document (submit)
 * - Store sensitive contract (submit)
 * - Update document (submit)
 * - Delete document
 */
export const StorageFormActions = ({
  isNewSensitiveContract = false,
  isNewDocument = false,
  onDeleteDocument,
  canRemoveDocument,
}: StorageFormActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ECM);
  const [deleting, setDeleting] = useState(false);

  const { showConversionNeededWarning } = useDocumentTypeContext();
  const shouldShowConversionWarning =
    showConversionNeededWarning && isNewDocument;

  const { showEcmContractManagementPromo } = useEcm();

  const { formState, watch } = useFormContext<StorageFormValues>();

  const documentType = watch('documentType');
  const isSubmitted = formState.isSubmitted;

  const hasValidationErrors = !isEmpty(formState.errors);
  const isFormSubmitting = formState.isSubmitting;

  let submitButtonText = t('storageForm.actions.updateDocument');

  if (isNewSensitiveContract) {
    submitButtonText = t('storageForm.actions.storeSensitiveContract');
  }

  if (isNewDocument && !isNewSensitiveContract) {
    submitButtonText = t('storageForm.actions.storeDocument');
  }

  const isContract = documentType === DocumentType.Contract;
  const showContractPromo = isContract && showEcmContractManagementPromo;
  const isSubmittedWithErrors = isSubmitted && hasValidationErrors;

  return (
    <FormAction status={isSubmittedWithErrors ? 'error' : 'default'}>
      <div className="grid gap-2">
        <SectionHeading as="h2">
          {t('storageForm.actions.title')}
        </SectionHeading>

        {isSubmittedWithErrors && (
          <Paragraph color="red500">
            {t('storageForm.actions.validationErrors.required')}
          </Paragraph>
        )}

        {showContractPromo && (
          <UpsellPromo
            feature={FEATURE.CONTRACTS}
            utmSource={CONTRACT_UTM_SOURCE.STORAGE_FORM}
          />
        )}

        <Button
          color="blue"
          loading={isFormSubmitting}
          type="submit"
          disabled={showContractPromo || shouldShowConversionWarning}
        >
          {submitButtonText}
        </Button>

        {canRemoveDocument && (
          <ConfirmationButton
            color="red"
            confirmationTitle={t(
              'storageForm.actions.deleteConfirmationMessage'
            )}
            disabled={deleting || isFormSubmitting}
            loading={deleting}
            onConfirm={async () => {
              setDeleting(true);
              await onDeleteDocument?.();
              setDeleting(false);
            }}
            variant="secondary"
          >
            {t('storageForm.actions.deleteDocument')}
          </ConfirmationButton>
        )}
      </div>
    </FormAction>
  );
};
