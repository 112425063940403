import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Image,
  MenuItem,
  Skeleton,
  Text,
  Tooltip,
  TruncatedText,
  usePopover,
  useTooltip,
} from '@candisio/design-system';
import {
  CollapsibleCard,
  getCardOpenState,
} from 'components/CollapsibleCard/CollapsibleCard';
import { InvalidTransactionInfoBox } from 'components/Transactions/TransactionDetailsCard/InvalidTransactionInfoBox';
import {
  type CardBrand,
  type CardType,
  EcmDocumentStatus,
  InvoiceAssociationStatus,
  type MemberInfo,
  TransactionStatus,
} from 'generated-types/graphql.types';
import { DocumentStatus } from 'generated-types/resolvers-types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isDocumentUnlinkable,
  useTransactionMenuItems,
} from 'views/Inbox/DocumentProcessing/components/DocumentRelations';
import { AutomatchedInfoBox } from './AutomatchedInfoBox';
import { CreditCardLinksTooltip } from './CreditCardLinksTooltip/CreditCardLinksTooltip';
import {
  TransactionDetails,
  TransactionDetailsReduced,
} from './TransactionDetails';
import { UnlinkActionConfirm } from './TransactionDetailsCardContextMenu';
import {
  PopoverDescription,
  type Transaction,
  TransactionDetailsCardProps,
  popoverDescription,
} from './utils';

import visaGrey from 'containers/credit-cards/CreditCard/media/visa-black.svg';
import { ActionsMenu } from 'views/Inbox/DocumentProcessing/components/ActionsMenu';
import { useUnlinkTransaction } from 'views/Inbox/hooks/useUnlinkTransaction';

const hiddenCardRefNum = '••••';

export const LoadingTransactionDetailsCard = () => {
  const isTransactionCardOpen = getCardOpenState<boolean>(
    TRANMSACTION_COLLAPSIBLE_CARD_ID
  );

  return (
    <>
      <CollapsibleCard
        id="transaction-loading"
        isStatic
        rowGap="unset"
        header={() => (
          <div className="grid gap-1 grid-flow-col justify-between">
            <div className="grid gap-1 grid-flow-col justify-start">
              {!isTransactionCardOpen && (
                <Skeleton height="space20" width="space24" />
              )}
              <Skeleton height="space20" width="space128" />
            </div>
            <Skeleton
              className="justify-self-end"
              height="space20"
              width="space24"
            />
          </div>
        )}
        headerProps={{
          background: 'gray0',
          paddingLeft: 'space8',
          paddingRight: 'space3',
          height: '40px',
          gap: 'space4',
        }}
        iconPosition="right"
      >
        {isTransactionCardOpen && (
          <div className="border-t border-t-gray-100 flex flex-col gap-1 items-stretch justify-center p-1.5 pt-2.5 bg-white">
            <div className="grid grid-flow-col justify-between items-center">
              <Skeleton height="space20" width="space24" />

              <Skeleton height="space16" width="space64" />
            </div>
            <div className="grid grid-flow-col justify-between items-center">
              <Skeleton height="space16" width="space128" />

              <Skeleton height="space16" width="space64" />
            </div>
            <div className="grid grid-flow-col justify-between items-center">
              <Skeleton height="space16" width="space64" />

              <Skeleton height="space16" width="space64" />
            </div>
            <Skeleton height="space20" width="space64" />
            <Skeleton height="space16" width="space200" />
          </div>
        )}
      </CollapsibleCard>
    </>
  );
};

export const TransactionDetailsCard = ({
  loading = false,
  transaction,
  contextMenu,
  contextMenuProps,
  headerWidth,
}: TransactionDetailsCardProps) => {
  const { isOnlyApprover } = useUserRoles();
  const user = useCurrentUser();
  const creditCardsAutomatedMatchingFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsAutomatedMatching
  );

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      placement: 'bottom',
    });

  if (loading) {
    return <LoadingTransactionDetailsCard />;
  }

  const {
    status,
    member,
    cardName,
    cardRefNum,
    localizedDeclineReason,
    invoiceAssociationStatus,
    cardId,
    cardBrand,
    cardType,
  } = transaction ?? {};

  const membershipId = member?.membershipId ?? undefined;
  const isTransactionOwner = membershipId === user?.id;

  const isTooltipHidden = isOnlyApprover && !isTransactionOwner;

  const isReversed = status === TransactionStatus.Reversed;
  const isDeclined = status === TransactionStatus.Declined;
  const isInvalidStatus = isReversed || isDeclined;

  const isTransactionAutomatched =
    invoiceAssociationStatus === InvoiceAssociationStatus.AutoMatched;

  const isAutomatchedInfoVisible =
    isTransactionAutomatched && creditCardsAutomatedMatchingFF;

  const automatchedInfo = isAutomatchedInfoVisible && <AutomatchedInfoBox />;
  const invalidStatus = isInvalidStatus && (
    <InvalidTransactionInfoBox
      status={status}
      localizedDeclineReason={localizedDeclineReason}
    />
  );

  return (
    <>
      <TransactionCardLayout
        member={member}
        contextMenu={contextMenu}
        cardName={cardName}
        cardType={cardType}
        cardBrand={cardBrand}
        triggerProps={triggerProps}
        triggerRef={triggerRef}
        cardRefNum={cardRefNum}
        transaction={transaction}
        automatchedInfo={automatchedInfo}
        invalidStatus={invalidStatus}
        contextMenuProps={contextMenuProps}
        headerWidth={headerWidth}
      />
      {isOpen && !isTooltipHidden && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <CreditCardLinksTooltip cardId={cardId} membershipId={membershipId} />
        </Tooltip>
      )}
    </>
  );
};

export const TransactionDetailsCardSummary = ({
  loading,
  transaction,
  documentId,
  documentStatus,
  contextMenuProps,
}: Omit<TransactionDetailsCardProps, 'contextMenu'> & {
  transaction?: Transaction;
  documentId?: string;
  documentStatus?: EcmDocumentStatus | DocumentStatus;
}) => {
  const [t] = useTranslation();
  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const { isUnlinkPending, unlinkTransaction } = useUnlinkTransaction(
    transaction?.id,
    documentId
  );

  const canUnlinkTransaction =
    !!documentStatus && isDocumentUnlinkable(documentStatus);
  const fallbackDescription =
    documentStatus &&
    popoverDescription[documentStatus as keyof PopoverDescription];

  const menuItems: MenuItem[] = useTransactionMenuItems(
    transaction,
    canUnlinkTransaction,
    openPopover
  );

  const handleConfirm = useCallback(async () => {
    await unlinkTransaction();
    closePopover();
  }, [unlinkTransaction, closePopover]);

  return (
    <Box borderRadius="medium">
      <TransactionDetailsCard
        loading={loading}
        transaction={transaction}
        contextMenu={
          <ActionsMenu
            label={t('documentContextMenu.openMenu')}
            isActionPending={false}
            items={menuItems}
            size="xxsmall"
            iconSize="space16"
            ref={triggerRef}
          />
        }
        contextMenuProps={{
          right: 0,
          top: 0,
          ...contextMenuProps,
        }}
        headerWidth="100%"
      />
      <UnlinkActionConfirm
        ref={popoverRef}
        popoverProps={popoverProps}
        isActionPending={isUnlinkPending}
        closePopover={closePopover}
        handleConfirm={handleConfirm}
        fallbackDescription={fallbackDescription}
      />
    </Box>
  );
};

type TransactionCardLayoutProps = {
  member?: MemberInfo;
  contextMenu: ReactNode;
  contextMenuProps?: BoxProps;
  cardName?: string;
  cardType?: CardType;
  cardBrand?: CardBrand;
  triggerProps: ReturnType<typeof useTooltip>['triggerProps'];
  triggerRef: ReturnType<typeof useTooltip>['triggerRef'];
  cardRefNum?: string;
  transaction?: Transaction;
  invalidStatus: ReactNode;
  automatchedInfo: ReactNode;
  headerWidth?: BoxProps['width'];
};

const TRANMSACTION_COLLAPSIBLE_CARD_ID = 'transaction';
const TransactionCardLayout = function TransactionCardLayout({
  member,
  contextMenu,
  cardName,
  cardRefNum,
  transaction,
  automatchedInfo,
  invalidStatus,
  contextMenuProps,
  headerWidth,
}: TransactionCardLayoutProps) {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const memberFullName = [member?.firstName, member?.lastName].join(' ');

  const header = (isOpen: boolean) => (
    <>
      <Box width={headerWidth ?? (contextMenu ? '90%' : '100%')}>
        {isOpen ? (
          <Text fontWeight="500">{t('transactionDetailsCard.category')}</Text>
        ) : (
          <TransactionDetailsReduced transaction={transaction} />
        )}
      </Box>
      {contextMenu && (
        <Box top="-space16" right="-space12" {...contextMenuProps}>
          {contextMenu}
        </Box>
      )}
    </>
  );

  return (
    <Box background="gray200" borderRadius="inherit">
      <CollapsibleCard
        id={TRANMSACTION_COLLAPSIBLE_CARD_ID}
        header={header}
        headerProps={{
          background: 'gray0',
          paddingLeft: 'space8',
          paddingRight: 'space3',
          height: '40px',
          gap: 'space4',
        }}
        iconPosition="right"
      >
        <Flex
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          padding="space10"
          paddingTop="space5"
          background="gray0"
          color="gray500"
        >
          <TransactionDetails transaction={transaction} />
          {/* // TODO: remove later */}
          <Box height="space4" />
          <TruncatedText maxWidth="20ch">{memberFullName}</TruncatedText>
          <Flex alignItems="center" gap="space4">
            <Image src={visaGrey} alt="" width="space32" />
            <Grid autoFlow="column" placeItems="center" columnGap="space4">
              <Text color="gray600">{hiddenCardRefNum}</Text>
              {cardRefNum}
            </Grid>
            {cardName && (
              <TruncatedText maxWidth="20ch">{'– ' + cardName}</TruncatedText>
            )}
          </Flex>
        </Flex>
        {automatchedInfo}
        {invalidStatus}
      </CollapsibleCard>
    </Box>
  );
};
