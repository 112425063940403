import { IconKey } from '@candisio/design-system';
import { Icon } from '@candisio/design-system/next';
import { MenuItem, MenuItemProps } from 'react-aria-components';
import styles from '../power-search-filters.module.css';

interface FilterItemProps extends MenuItemProps {
  icon: IconKey;
}

export const FilterItem = (props: FilterItemProps) => {
  const textValue =
    props.textValue ||
    (typeof props.children === 'string' ? props.children : undefined);
  return (
    <MenuItem {...props} textValue={textValue} className={styles.filterItem}>
      {({ hasSubmenu }) => (
        <>
          <Icon icon={props.icon} />
          {props.children}
          {hasSubmenu && <Icon icon="caretRight" />}
        </>
      )}
    </MenuItem>
  );
};
