import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { RejectReimbursmentCommentForm } from '../RejectReimbursement/RejectReimbursmentCommentForm';
import { EditModeAction } from './EditModeAction';
import { CommentFormOutput } from '../SendBackToEmploy/utils';
import { SendBackToEmployeeForm } from '../SendBackToEmploy/SendBackToEmployeeForm';
import { Dispatch, SetStateAction } from 'react';
import { ApprovingFooterMode } from './ApprovingStatusFooterActionBox';
import { ReimbursementItemForList } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';

interface CommentFormContainerProps {
  isRejectPending: boolean;
  isSendBackFormVisible: boolean;
  isCommentFormVisible: boolean;
  onEditModeToggle: () => void;
  approvingFooterMode: ApprovingFooterMode;
  onSetIsCommentFormVisible: Dispatch<SetStateAction<boolean>>;
  onSetIsSendBackFormVisible: Dispatch<SetStateAction<boolean>>;
  onRejectReimbursement: (comment: string) => Promise<void>;
  reimbursement?: Reimbursement;
  isRequestAmendmentPending: boolean;
  reimbursementItems: ReimbursementItemForList[];
  onRequestAmendment: ({ comment }: CommentFormOutput) => Promise<void>;
}

export const CommentFormContainer = ({
  isRejectPending,
  isSendBackFormVisible,
  isCommentFormVisible,
  approvingFooterMode,
  onEditModeToggle,
  onSetIsCommentFormVisible,
  onSetIsSendBackFormVisible,
  onRejectReimbursement,
  reimbursement,
  isRequestAmendmentPending,
  reimbursementItems,
  onRequestAmendment,
}: CommentFormContainerProps) => {
  if (isSendBackFormVisible) {
    return (
      <SendBackToEmployeeForm
        onSetIsFormVisible={onSetIsSendBackFormVisible}
        onRequestAmendment={onRequestAmendment}
        isRequestAmendmentPending={isRequestAmendmentPending}
      />
    );
  }

  if (isCommentFormVisible) {
    return (
      <RejectReimbursmentCommentForm
        onSetIsFormVisible={onSetIsCommentFormVisible}
        onRejectReimbursment={onRejectReimbursement}
        isRejectPending={isRejectPending}
        reimbursement={reimbursement}
        reimbursementItems={reimbursementItems}
        toggleAction={
          <EditModeAction
            approvingFooterMode={approvingFooterMode}
            onClick={onEditModeToggle}
          />
        }
      />
    );
  }

  return null;
};
