import styles from '../DaysStep.module.css';
import { ReimbursementItemFormPerDiemDay } from 'views/Reimbursement/toolkit/reimbursementItemsFormSchema';
import { PerDiemMealsMultiSelectorButtons } from '../PerDiemMealsMultiSelectorButtons/PerDiemMealsMultiSelectorButtons';
import { perDiemMealbuttons } from '../../PerDiemForm';
import {
  GetMultiSelectorStatePerDiemParams,
  SelectAllColumnPerDiemParams,
} from 'views/Reimbursement/hooks/types';
import { useTranslation } from 'react-i18next';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { Button } from '@candisio/design-system';
import { SelectionVariant } from '../../utils/getMealsSelectorVariant';

interface ItemSummaryProps {
  onSetIsMiddleDayHidden: (value: boolean) => void;
  onSelectAllColumn: (params: SelectAllColumnPerDiemParams) => void;
  onGetMultiSelectorState: (
    params: GetMultiSelectorStatePerDiemParams
  ) => SelectionVariant;
  collapsibleDays: (ReimbursementItemFormPerDiemDay & { id: string })[];
}

export const ItemSummary = ({
  collapsibleDays,
  onSetIsMiddleDayHidden,
  onSelectAllColumn,
  onGetMultiSelectorState,
}: ItemSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const numberOfCollapsibleDays = collapsibleDays.length;

  return (
    <li data-testid="collapsible-item-days-list" className={styles['day-item']}>
      <Button
        icon="sortInactive"
        variant="tertiary"
        size="small"
        iconSize="20px"
        className="justify-self-start right-[12px]"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onSetIsMiddleDayHidden(false);
        }}
      >
        {t('reimbursementView.middleSection.form.perDiem.days.hiddenDays', {
          count: numberOfCollapsibleDays,
        })}
      </Button>

      <PerDiemMealsMultiSelectorButtons
        buttons={perDiemMealbuttons}
        onSelectAll={onSelectAllColumn}
        onGetMultiSelectorState={onGetMultiSelectorState}
      />
    </li>
  );
};
